/*--------------------------------

awesomeicons Web Font
Generated using nucleoapp.com

-------------------------------- */
@font-face {
  font-family: 'awesomeicons';
  src: url('../../public/fonts/awesomeicons.eot');
  src: url('../../public/fonts/awesomeicons.eot') format('embedded-opentype'), url('../../public/fonts/awesomeicons.woff2') format('woff2'), url('../../public/fonts/awesomeicons.woff') format('woff'), url('../../public/fonts/awesomeicons.ttf') format('truetype'), url('../../public/fonts/awesomeicons.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
/*------------------------
	base class definition
-------------------------*/
.icon {
  display: inline-block;
  font: normal normal normal 1em/1 'awesomeicons';
  color: inherit;
  flex-shrink: 0;
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*------------------------
  change icon size
-------------------------*/
/* relative units */
.icon-xsm {
  font-size: 1.4em;
}
.icon-sm {
  font-size: 1.6em;
}
.icon-md {
  font-size: 1.8em;
}
.icon-lg {
  font-size: 2.0em;
}
.icon-xlg {
  font-size: 2.2em;
}
.icon-xxlg {
  font-size: 2.4em;
}
.icon-xxxlg {
  font-size: 4em;
}

/*------------------------
  spinning icons
-------------------------*/
.icon-is-spinning {
  animation: icon-spin 1s infinite linear;
}
@keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*------------------------
  rotated/flipped icons
-------------------------*/
.icon-rotate-90 {
  transform: rotate(90deg);
}
.icon-rotate-180 {
  transform: rotate(180deg);
}
.icon-rotate-270 {
  transform: rotate(270deg);
}
.icon-flip-y {
  transform: scaleY(-1);
}
.icon-flip-x {
  transform: scaleX(-1);
}


/*------------------------
	colors
-------------------------*/

.icon-black {
  color: #000000;
}

.icon-white {
  color: #ffffff;
}

.icon-blue-primary {
  color: #1976D2;
}

.icon-red-primary {
  color: #EA2A2F;
}

.icon-yellow-primary {
  color: #FFB800;
}

/*------------------------
	icons
-------------------------*/

.icon-activity-filled::before {
  content: "\ea02";
}

.icon-activity::before {
  content: "\ea03";
}

.icon-airplane-filled::before {
  content: "\ea04";
}

.icon-airplane-in-flight-filled::before {
  content: "\ea05";
}

.icon-airplane-in-flight::before {
  content: "\ea06";
}

.icon-airplane-landing-filled::before {
  content: "\ea07";
}

.icon-airplane-landing::before {
  content: "\ea08";
}

.icon-airplane-takeoff-filled::before {
  content: "\ea09";
}

.icon-airplane-takeoff::before {
  content: "\ea0a";
}

.icon-airplane-tilt-filled::before {
  content: "\ea0b";
}

.icon-airplane-tilt::before {
  content: "\ea0c";
}

.icon-airplane::before {
  content: "\ea0d";
}

.icon-airplay-filled::before {
  content: "\ea0e";
}

.icon-airplay::before {
  content: "\ea0f";
}

.icon-alarm-filled::before {
  content: "\ea10";
}

.icon-alarm::before {
  content: "\ea11";
}

.icon-align-bottom-filled::before {
  content: "\ea12";
}

.icon-align-bottom::before {
  content: "\ea13";
}

.icon-align-center-horizontal-filled::before {
  content: "\ea14";
}

.icon-align-center-horizontal::before {
  content: "\ea15";
}

.icon-align-center-vertical-filled::before {
  content: "\ea16";
}

.icon-align-center-vertical::before {
  content: "\ea17";
}

.icon-align-left-filled::before {
  content: "\ea18";
}

.icon-align-left::before {
  content: "\ea19";
}

.icon-align-right-filled::before {
  content: "\ea1a";
}

.icon-align-right::before {
  content: "\ea1b";
}

.icon-align-top-filled::before {
  content: "\ea1c";
}

.icon-align-top::before {
  content: "\ea1d";
}

.icon-anchor-filled::before {
  content: "\ea1e";
}

.icon-anchor-simple-filled::before {
  content: "\ea1f";
}

.icon-anchor-simple::before {
  content: "\ea20";
}

.icon-anchor::before {
  content: "\ea21";
}

.icon-android-logo-filled::before {
  content: "\ea22";
}

.icon-android-logo::before {
  content: "\ea23";
}

.icon-aperture-filled::before {
  content: "\ea24";
}

.icon-aperture::before {
  content: "\ea25";
}

.icon-app-window-filled::before {
  content: "\ea26";
}

.icon-app-window::before {
  content: "\ea27";
}

.icon-apple-logo-filled::before {
  content: "\ea28";
}

.icon-apple-logo::before {
  content: "\ea29";
}

.icon-archive-box-filled::before {
  content: "\ea2a";
}

.icon-archive-box::before {
  content: "\ea2b";
}

.icon-archive-filled::before {
  content: "\ea2c";
}

.icon-archive-tray-filled::before {
  content: "\ea2d";
}

.icon-archive-tray::before {
  content: "\ea2e";
}

.icon-archive::before {
  content: "\ea2f";
}

.icon-armchair-filled::before {
  content: "\ea30";
}

.icon-armchair::before {
  content: "\ea31";
}

.icon-arrow-arc-left-filled::before {
  content: "\ea32";
}

.icon-arrow-arc-left::before {
  content: "\ea33";
}

.icon-arrow-arc-right-filled::before {
  content: "\ea34";
}

.icon-arrow-arc-right::before {
  content: "\ea35";
}

.icon-arrow-bend-double-up-left-filled::before {
  content: "\ea36";
}

.icon-arrow-bend-double-up-left::before {
  content: "\ea37";
}

.icon-arrow-bend-double-up-right-filled::before {
  content: "\ea38";
}

.icon-arrow-bend-double-up-right::before {
  content: "\ea39";
}

.icon-arrow-bend-down-left-filled::before {
  content: "\ea3a";
}

.icon-arrow-bend-down-left::before {
  content: "\ea3b";
}

.icon-arrow-bend-down-right-filled::before {
  content: "\ea3c";
}

.icon-arrow-bend-down-right::before {
  content: "\ea3d";
}

.icon-arrow-bend-left-down-filled::before {
  content: "\ea3e";
}

.icon-arrow-bend-left-down::before {
  content: "\ea3f";
}

.icon-arrow-bend-left-up-filled::before {
  content: "\ea40";
}

.icon-arrow-bend-left-up::before {
  content: "\ea41";
}

.icon-arrow-bend-right-down-filled::before {
  content: "\ea42";
}

.icon-arrow-bend-right-down::before {
  content: "\ea43";
}

.icon-arrow-bend-right-up-filled::before {
  content: "\ea44";
}

.icon-arrow-bend-right-up::before {
  content: "\ea45";
}

.icon-arrow-bend-up-left-filled::before {
  content: "\ea46";
}

.icon-arrow-bend-up-left::before {
  content: "\ea47";
}

.icon-arrow-bend-up-right-filled::before {
  content: "\ea48";
}

.icon-arrow-bend-up-right::before {
  content: "\ea49";
}

.icon-arrow-circle-down-filled::before {
  content: "\ea4a";
}

.icon-arrow-circle-down-left-filled::before {
  content: "\ea4b";
}

.icon-arrow-circle-down-left::before {
  content: "\ea4c";
}

.icon-arrow-circle-down-right-filled::before {
  content: "\ea4d";
}

.icon-arrow-circle-down-right::before {
  content: "\ea4e";
}

.icon-arrow-circle-down::before {
  content: "\ea4f";
}

.icon-arrow-circle-left-filled::before {
  content: "\ea50";
}

.icon-arrow-circle-left::before {
  content: "\ea51";
}

.icon-arrow-circle-right-filled::before {
  content: "\ea52";
}

.icon-arrow-circle-right::before {
  content: "\ea53";
}

.icon-arrow-circle-up-filled::before {
  content: "\ea54";
}

.icon-arrow-circle-up-left-filled::before {
  content: "\ea55";
}

.icon-arrow-circle-up-left::before {
  content: "\ea56";
}

.icon-arrow-circle-up-right-filled::before {
  content: "\ea57";
}

.icon-arrow-circle-up-right::before {
  content: "\ea58";
}

.icon-arrow-circle-up::before {
  content: "\ea59";
}

.icon-arrow-clockwise-filled::before {
  content: "\ea5a";
}

.icon-arrow-clockwise::before {
  content: "\ea5b";
}

.icon-arrow-counter-clockwise-filled::before {
  content: "\ea5c";
}

.icon-arrow-counter-clockwise::before {
  content: "\ea5d";
}

.icon-arrow-down-filled::before {
  content: "\ea5e";
}

.icon-arrow-down-left-filled::before {
  content: "\ea5f";
}

.icon-arrow-down-left::before {
  content: "\ea60";
}

.icon-arrow-down-right-filled::before {
  content: "\ea61";
}

.icon-arrow-down-right::before {
  content: "\ea62";
}

.icon-arrow-down::before {
  content: "\ea63";
}

.icon-arrow-elbow-down-left-filled::before {
  content: "\ea64";
}

.icon-arrow-elbow-down-left::before {
  content: "\ea65";
}

.icon-arrow-elbow-down-right-filled::before {
  content: "\ea66";
}

.icon-arrow-elbow-down-right::before {
  content: "\ea67";
}

.icon-arrow-elbow-left-down-filled::before {
  content: "\ea68";
}

.icon-arrow-elbow-left-down::before {
  content: "\ea69";
}

.icon-arrow-elbow-left-filled::before {
  content: "\ea6a";
}

.icon-arrow-elbow-left-up-filled::before {
  content: "\ea6b";
}

.icon-arrow-elbow-left-up::before {
  content: "\ea6c";
}

.icon-arrow-elbow-left::before {
  content: "\ea6d";
}

.icon-arrow-elbow-right-down-filled::before {
  content: "\ea6e";
}

.icon-arrow-elbow-right-down::before {
  content: "\ea6f";
}

.icon-arrow-elbow-right-filled::before {
  content: "\ea70";
}

.icon-arrow-elbow-right-up-filled::before {
  content: "\ea71";
}

.icon-arrow-elbow-right-up::before {
  content: "\ea72";
}

.icon-arrow-elbow-right::before {
  content: "\ea73";
}

.icon-arrow-elbow-up-left-filled::before {
  content: "\ea74";
}

.icon-arrow-elbow-up-left::before {
  content: "\ea75";
}

.icon-arrow-elbow-up-right-filled::before {
  content: "\ea76";
}

.icon-arrow-elbow-up-right::before {
  content: "\ea77";
}

.icon-arrow-fat-down-filled::before {
  content: "\ea78";
}

.icon-arrow-fat-down::before {
  content: "\ea79";
}

.icon-arrow-fat-left-filled::before {
  content: "\ea7a";
}

.icon-arrow-fat-left::before {
  content: "\ea7b";
}

.icon-arrow-fat-line-down-filled::before {
  content: "\ea7c";
}

.icon-arrow-fat-line-down::before {
  content: "\ea7d";
}

.icon-arrow-fat-line-left-filled::before {
  content: "\ea7e";
}

.icon-arrow-fat-line-left::before {
  content: "\ea7f";
}

.icon-arrow-fat-line-right-filled::before {
  content: "\ea80";
}

.icon-arrow-fat-line-right::before {
  content: "\ea81";
}

.icon-arrow-fat-line-up-filled::before {
  content: "\ea82";
}

.icon-arrow-fat-line-up::before {
  content: "\ea83";
}

.icon-arrow-fat-lines-down-filled::before {
  content: "\ea84";
}

.icon-arrow-fat-lines-down::before {
  content: "\ea85";
}

.icon-arrow-fat-lines-left-filled::before {
  content: "\ea86";
}

.icon-arrow-fat-lines-left::before {
  content: "\ea87";
}

.icon-arrow-fat-lines-right-filled::before {
  content: "\ea88";
}

.icon-arrow-fat-lines-right::before {
  content: "\ea89";
}

.icon-arrow-fat-lines-up-filled::before {
  content: "\ea8a";
}

.icon-arrow-fat-lines-up::before {
  content: "\ea8b";
}

.icon-arrow-fat-right-filled::before {
  content: "\ea8c";
}

.icon-arrow-fat-right::before {
  content: "\ea8d";
}

.icon-arrow-fat-up-filled::before {
  content: "\ea8e";
}

.icon-arrow-fat-up::before {
  content: "\ea8f";
}

.icon-arrow-left-filled::before {
  content: "\ea90";
}

.icon-arrow-left::before {
  content: "\ea91";
}

.icon-arrow-line-down-filled::before {
  content: "\ea92";
}

.icon-arrow-line-down-left-filled::before {
  content: "\ea93";
}

.icon-arrow-line-down-left::before {
  content: "\ea94";
}

.icon-arrow-line-down-right-filled::before {
  content: "\ea95";
}

.icon-arrow-line-down-right::before {
  content: "\ea96";
}

.icon-arrow-line-down::before {
  content: "\ea97";
}

.icon-arrow-line-left-filled::before {
  content: "\ea98";
}

.icon-arrow-line-left::before {
  content: "\ea99";
}

.icon-arrow-line-right-filled::before {
  content: "\ea9a";
}

.icon-arrow-line-right::before {
  content: "\ea9b";
}

.icon-arrow-line-up-filled::before {
  content: "\ea9c";
}

.icon-arrow-line-up-left-filled::before {
  content: "\ea9d";
}

.icon-arrow-line-up-left::before {
  content: "\ea9e";
}

.icon-arrow-line-up-right-filled::before {
  content: "\ea9f";
}

.icon-arrow-line-up-right::before {
  content: "\eaa0";
}

.icon-arrow-line-up::before {
  content: "\eaa1";
}

.icon-arrow-right-filled::before {
  content: "\eaa2";
}

.icon-arrow-right::before {
  content: "\eaa3";
}

.icon-arrow-square-down-filled::before {
  content: "\eaa4";
}

.icon-arrow-square-down-left-filled::before {
  content: "\eaa5";
}

.icon-arrow-square-down-left::before {
  content: "\eaa6";
}

.icon-arrow-square-down-right-filled::before {
  content: "\eaa7";
}

.icon-arrow-square-down-right::before {
  content: "\eaa8";
}

.icon-arrow-square-down::before {
  content: "\eaa9";
}

.icon-arrow-square-in-filled::before {
  content: "\eaaa";
}

.icon-arrow-square-in::before {
  content: "\eaab";
}

.icon-arrow-square-left-filled::before {
  content: "\eaac";
}

.icon-arrow-square-left::before {
  content: "\eaad";
}

.icon-arrow-square-out-filled::before {
  content: "\eaae";
}

.icon-arrow-square-out::before {
  content: "\eaaf";
}

.icon-arrow-square-right-filled::before {
  content: "\eab0";
}

.icon-arrow-square-right::before {
  content: "\eab1";
}

.icon-arrow-square-up-filled::before {
  content: "\eab2";
}

.icon-arrow-square-up-left-filled::before {
  content: "\eab3";
}

.icon-arrow-square-up-left::before {
  content: "\eab4";
}

.icon-arrow-square-up-right-filled::before {
  content: "\eab5";
}

.icon-arrow-square-up-right::before {
  content: "\eab6";
}

.icon-arrow-square-up::before {
  content: "\eab7";
}

.icon-arrow-udown-left-filled::before {
  content: "\eab8";
}

.icon-arrow-udown-left::before {
  content: "\eab9";
}

.icon-arrow-udown-right-filled::before {
  content: "\eaba";
}

.icon-arrow-udown-right::before {
  content: "\eabb";
}

.icon-arrow-uleft-down-filled::before {
  content: "\eabc";
}

.icon-arrow-uleft-down::before {
  content: "\eabd";
}

.icon-arrow-uleft-up-filled::before {
  content: "\eabe";
}

.icon-arrow-uleft-up::before {
  content: "\eabf";
}

.icon-arrow-up-filled::before {
  content: "\eac0";
}

.icon-arrow-up-left-filled::before {
  content: "\eac1";
}

.icon-arrow-up-left::before {
  content: "\eac2";
}

.icon-arrow-up-right-filled::before {
  content: "\eac3";
}

.icon-arrow-up-right::before {
  content: "\eac4";
}

.icon-arrow-up::before {
  content: "\eac5";
}

.icon-arrow-uright-down-filled::before {
  content: "\eac6";
}

.icon-arrow-uright-down::before {
  content: "\eac7";
}

.icon-arrow-uright-up-filled::before {
  content: "\eac8";
}

.icon-arrow-uright-up::before {
  content: "\eac9";
}

.icon-arrow-uup-left-filled::before {
  content: "\eaca";
}

.icon-arrow-uup-left::before {
  content: "\eacb";
}

.icon-arrow-uup-right-filled::before {
  content: "\eacc";
}

.icon-arrow-uup-right::before {
  content: "\eacd";
}

.icon-arrows-clockwise-filled::before {
  content: "\eace";
}

.icon-arrows-clockwise::before {
  content: "\eacf";
}

.icon-arrows-counter-clockwise-filled::before {
  content: "\ead0";
}

.icon-arrows-counter-clockwise::before {
  content: "\ead1";
}

.icon-arrows-down-up-filled::before {
  content: "\ead2";
}

.icon-arrows-down-up::before {
  content: "\ead3";
}

.icon-arrows-in-cardinal-filled::before {
  content: "\ead4";
}

.icon-arrows-in-cardinal::before {
  content: "\ead5";
}

.icon-arrows-in-filled::before {
  content: "\ead6";
}

.icon-arrows-in-line-horizontal-filled::before {
  content: "\ead7";
}

.icon-arrows-in-line-horizontal::before {
  content: "\ead8";
}

.icon-arrows-in-line-vertical-filled::before {
  content: "\ead9";
}

.icon-arrows-in-line-vertical::before {
  content: "\eada";
}

.icon-arrows-in-simple-filled::before {
  content: "\eadb";
}

.icon-arrows-in-simple::before {
  content: "\eadc";
}

.icon-arrows-in::before {
  content: "\eadd";
}

.icon-arrows-left-right-filled::before {
  content: "\eade";
}

.icon-arrows-left-right::before {
  content: "\eadf";
}

.icon-arrows-out-cardinal-filled::before {
  content: "\eae0";
}

.icon-arrows-out-cardinal::before {
  content: "\eae1";
}

.icon-arrows-out-filled::before {
  content: "\eae2";
}

.icon-arrows-out-line-horizontal-filled::before {
  content: "\eae3";
}

.icon-arrows-out-line-horizontal::before {
  content: "\eae4";
}

.icon-arrows-out-line-vertical-filled::before {
  content: "\eae5";
}

.icon-arrows-out-line-vertical::before {
  content: "\eae6";
}

.icon-arrows-out-simple-filled::before {
  content: "\eae7";
}

.icon-arrows-out-simple::before {
  content: "\eae8";
}

.icon-arrows-out::before {
  content: "\eae9";
}

.icon-article-filled::before {
  content: "\eaea";
}

.icon-article-medium-filled::before {
  content: "\eaeb";
}

.icon-article-medium::before {
  content: "\eaec";
}

.icon-article-ny-times-filled::before {
  content: "\eaed";
}

.icon-article-ny-times::before {
  content: "\eaee";
}

.icon-article::before {
  content: "\eaef";
}

.icon-asterisk-filled::before {
  content: "\eaf0";
}

.icon-asterisk::before {
  content: "\eaf1";
}

.icon-at-filled::before {
  content: "\eaf2";
}

.icon-at::before {
  content: "\eaf3";
}

.icon-atom-filled::before {
  content: "\eaf4";
}

.icon-atom::before {
  content: "\eaf5";
}

.icon-backspace-filled::before {
  content: "\eaf6";
}

.icon-backspace::before {
  content: "\eaf7";
}

.icon-bag-filled::before {
  content: "\eaf8";
}

.icon-bag-simple-filled::before {
  content: "\eaf9";
}

.icon-bag-simple::before {
  content: "\eafa";
}

.icon-bag::before {
  content: "\eafb";
}

.icon-bandaids-filled::before {
  content: "\eafc";
}

.icon-bandaids::before {
  content: "\eafd";
}

.icon-bank-filled::before {
  content: "\eafe";
}

.icon-bank::before {
  content: "\eaff";
}

.icon-barbell-filled::before {
  content: "\eb00";
}

.icon-barbell::before {
  content: "\eb01";
}

.icon-barcode-filled::before {
  content: "\eb02";
}

.icon-barcode-slash::before {
  content: "\eb03";
}

.icon-barcode::before {
  content: "\eb04";
}

.icon-battery-charging-filled::before {
  content: "\eb05";
}

.icon-battery-charging-vertical-filled::before {
  content: "\eb06";
}

.icon-battery-charging-vertical::before {
  content: "\eb07";
}

.icon-battery-charging::before {
  content: "\eb08";
}

.icon-battery-empty-filled::before {
  content: "\eb09";
}

.icon-battery-empty::before {
  content: "\eb0a";
}

.icon-battery-full-filled::before {
  content: "\eb0b";
}

.icon-battery-full::before {
  content: "\eb0c";
}

.icon-battery-high-filled::before {
  content: "\eb0d";
}

.icon-battery-high::before {
  content: "\eb0e";
}

.icon-battery-low-filled::before {
  content: "\eb0f";
}

.icon-battery-low::before {
  content: "\eb10";
}

.icon-battery-medium-filled::before {
  content: "\eb11";
}

.icon-battery-medium::before {
  content: "\eb12";
}

.icon-battery-warning-filled::before {
  content: "\eb13";
}

.icon-battery-warning-vertical-filled::before {
  content: "\eb14";
}

.icon-battery-warning-vertical::before {
  content: "\eb15";
}

.icon-battery-warning::before {
  content: "\eb16";
}

.icon-bed-filled::before {
  content: "\eb17";
}

.icon-bed::before {
  content: "\eb18";
}

.icon-bell-filled::before {
  content: "\eb19";
}

.icon-bell-ringing-filled::before {
  content: "\eb1a";
}

.icon-bell-ringing::before {
  content: "\eb1b";
}

.icon-bell-simple-filled::before {
  content: "\eb1c";
}

.icon-bell-simple-ringing-filled::before {
  content: "\eb1d";
}

.icon-bell-simple-ringing::before {
  content: "\eb1e";
}

.icon-bell-simple-slash-filled::before {
  content: "\eb1f";
}

.icon-bell-simple-slash::before {
  content: "\eb20";
}

.icon-bell-simple-z-filled::before {
  content: "\eb21";
}

.icon-bell-simple-z::before {
  content: "\eb22";
}

.icon-bell-simple::before {
  content: "\eb23";
}

.icon-bell-slash-filled::before {
  content: "\eb24";
}

.icon-bell-slash::before {
  content: "\eb25";
}

.icon-bell-z-filled::before {
  content: "\eb26";
}

.icon-bell-z::before {
  content: "\eb27";
}

.icon-bell::before {
  content: "\eb28";
}

.icon-bicycle-filled::before {
  content: "\eb29";
}

.icon-bicycle-helmet::before {
  content: "\eb2a";
}

.icon-bicycle::before {
  content: "\eb2b";
}

.icon-bluetooth-connected-filled::before {
  content: "\eb2c";
}

.icon-bluetooth-connected::before {
  content: "\eb2d";
}

.icon-bluetooth-filled::before {
  content: "\eb2e";
}

.icon-bluetooth-slash-filled::before {
  content: "\eb2f";
}

.icon-bluetooth-slash::before {
  content: "\eb30";
}

.icon-bluetooth-x-filled::before {
  content: "\eb31";
}

.icon-bluetooth-x::before {
  content: "\eb32";
}

.icon-bluetooth::before {
  content: "\eb33";
}

.icon-book-bookmark-filled::before {
  content: "\eb34";
}

.icon-book-bookmark::before {
  content: "\eb35";
}

.icon-book-filled::before {
  content: "\eb36";
}

.icon-book-open-filled::before {
  content: "\eb37";
}

.icon-book-open::before {
  content: "\eb38";
}

.icon-book::before {
  content: "\eb39";
}

.icon-bookmark-filled::before {
  content: "\eb3a";
}

.icon-bookmark-simple-filled::before {
  content: "\eb3b";
}

.icon-bookmark-simple::before {
  content: "\eb3c";
}

.icon-bookmark::before {
  content: "\eb3d";
}

.icon-bookmarks-filled::before {
  content: "\eb3e";
}

.icon-bookmarks-simple-filled::before {
  content: "\eb3f";
}

.icon-bookmarks-simple::before {
  content: "\eb40";
}

.icon-bookmarks::before {
  content: "\eb41";
}

.icon-briefcase-filled::before {
  content: "\eb42";
}

.icon-briefcase-metal-filled::before {
  content: "\eb43";
}

.icon-briefcase-metal::before {
  content: "\eb44";
}

.icon-briefcase::before {
  content: "\eb45";
}

.icon-broadcast-filled::before {
  content: "\eb46";
}

.icon-broadcast::before {
  content: "\eb47";
}

.icon-browser-filled::before {
  content: "\eb48";
}

.icon-browser::before {
  content: "\eb49";
}

.icon-browsers-filled::before {
  content: "\eb4a";
}

.icon-browsers::before {
  content: "\eb4b";
}

.icon-bug-beetle-filled::before {
  content: "\eb4c";
}

.icon-bug-beetle::before {
  content: "\eb4d";
}

.icon-bug-droid-filled::before {
  content: "\eb4e";
}

.icon-bug-droid::before {
  content: "\eb4f";
}

.icon-bug-filled::before {
  content: "\eb50";
}

.icon-bug::before {
  content: "\eb51";
}

.icon-buildings-filled::before {
  content: "\eb52";
}

.icon-buildings::before {
  content: "\eb53";
}

.icon-bus-filled::before {
  content: "\eb54";
}

.icon-bus::before {
  content: "\eb55";
}

.icon-calculator-filled::before {
  content: "\eb56";
}

.icon-calculator::before {
  content: "\eb57";
}

.icon-calendar-blank-filled::before {
  content: "\eb58";
}

.icon-calendar-blank::before {
  content: "\eb59";
}

.icon-calendar-filled::before {
  content: "\eb5a";
}

.icon-calendar-x-filled::before {
  content: "\eb5b";
}

.icon-calendar-x::before {
  content: "\eb5c";
}

.icon-calendar::before {
  content: "\eb5d";
}

.icon-camera-filled::before {
  content: "\eb5e";
}

.icon-camera-slash-filled::before {
  content: "\eb5f";
}

.icon-camera-slash::before {
  content: "\eb60";
}

.icon-camera::before {
  content: "\eb61";
}

.icon-car-filled::before {
  content: "\eb62";
}

.icon-car-simple-filled::before {
  content: "\eb63";
}

.icon-car-simple::before {
  content: "\eb64";
}

.icon-car::before {
  content: "\eb65";
}

.icon-cardholder-filled::before {
  content: "\eb66";
}

.icon-cardholder::before {
  content: "\eb67";
}

.icon-cards-filled::before {
  content: "\eb68";
}

.icon-cards::before {
  content: "\eb69";
}

.icon-caret-circle-double-down-filled::before {
  content: "\eb6a";
}

.icon-caret-circle-double-down::before {
  content: "\eb6b";
}

.icon-caret-circle-double-left-filled::before {
  content: "\eb6c";
}

.icon-caret-circle-double-left::before {
  content: "\eb6d";
}

.icon-caret-circle-double-right-filled::before {
  content: "\eb6e";
}

.icon-caret-circle-double-right::before {
  content: "\eb6f";
}

.icon-caret-circle-double-up-filled::before {
  content: "\eb70";
}

.icon-caret-circle-double-up::before {
  content: "\eb71";
}

.icon-caret-circle-down-filled::before {
  content: "\eb72";
}

.icon-caret-circle-down::before {
  content: "\eb73";
}

.icon-caret-circle-left-filled::before {
  content: "\eb74";
}

.icon-caret-circle-left::before {
  content: "\eb75";
}

.icon-caret-circle-right-filled::before {
  content: "\eb76";
}

.icon-caret-circle-right::before {
  content: "\eb77";
}

.icon-caret-circle-up-filled::before {
  content: "\eb78";
}

.icon-caret-circle-up::before {
  content: "\eb79";
}

.icon-caret-double-down-filled::before {
  content: "\eb7a";
}

.icon-caret-double-down::before {
  content: "\eb7b";
}

.icon-caret-double-left-filled::before {
  content: "\eb7c";
}

.icon-caret-double-left::before {
  content: "\eb7d";
}

.icon-caret-double-right-filled::before {
  content: "\eb7e";
}

.icon-caret-double-right::before {
  content: "\eb7f";
}

.icon-caret-double-up-filled::before {
  content: "\eb80";
}

.icon-caret-double-up::before {
  content: "\eb81";
}

.icon-caret-down-1::before {
  content: "\eb82";
}

.icon-caret-down-filled::before {
  content: "\eb83";
}

.icon-caret-down::before {
  content: "\eb84";
}

.icon-caret-left-filled::before {
  content: "\eb85";
}

.icon-caret-left::before {
  content: "\eb86";
}

.icon-caret-right-filled::before {
  content: "\eb87";
}

.icon-caret-right::before {
  content: "\eb88";
}

.icon-caret-up-filled::before {
  content: "\eb89";
}

.icon-caret-up::before {
  content: "\eb8a";
}

.icon-cell-signal-full-filled::before {
  content: "\eb8b";
}

.icon-cell-signal-full::before {
  content: "\eb8c";
}

.icon-cell-signal-high-filled::before {
  content: "\eb8d";
}

.icon-cell-signal-high::before {
  content: "\eb8e";
}

.icon-cell-signal-low-filled::before {
  content: "\eb8f";
}

.icon-cell-signal-low::before {
  content: "\eb90";
}

.icon-cell-signal-medium-filled::before {
  content: "\eb91";
}

.icon-cell-signal-medium::before {
  content: "\eb92";
}

.icon-cell-signal-none-filled::before {
  content: "\eb93";
}

.icon-cell-signal-none::before {
  content: "\eb94";
}

.icon-cell-signal-slash-filled::before {
  content: "\eb95";
}

.icon-cell-signal-slash::before {
  content: "\eb96";
}

.icon-cell-signal-x-filled::before {
  content: "\eb97";
}

.icon-cell-signal-x::before {
  content: "\eb98";
}

.icon-chalkboard-filled::before {
  content: "\eb99";
}

.icon-chalkboard-simple-filled::before {
  content: "\eb9a";
}

.icon-chalkboard-simple::before {
  content: "\eb9b";
}

.icon-chalkboard-teacher-filled::before {
  content: "\eb9c";
}

.icon-chalkboard-teacher::before {
  content: "\eb9d";
}

.icon-chalkboard::before {
  content: "\eb9e";
}

.icon-chart-bar-filled::before {
  content: "\eb9f";
}

.icon-chart-bar-horizontal-filled::before {
  content: "\eba0";
}

.icon-chart-bar-horizontal::before {
  content: "\eba1";
}

.icon-chart-bar::before {
  content: "\eba2";
}

.icon-chart-line-filled::before {
  content: "\eba3";
}

.icon-chart-line-up-filled::before {
  content: "\eba4";
}

.icon-chart-line-up::before {
  content: "\eba5";
}

.icon-chart-line::before {
  content: "\eba6";
}

.icon-chart-pie-filled::before {
  content: "\eba7";
}

.icon-chart-pie-slice-filled::before {
  content: "\eba8";
}

.icon-chart-pie-slice::before {
  content: "\eba9";
}

.icon-chart-pie::before {
  content: "\ebaa";
}

.icon-chat-centered-dots-filled::before {
  content: "\ebab";
}

.icon-chat-centered-dots::before {
  content: "\ebac";
}

.icon-chat-centered-filled::before {
  content: "\ebad";
}

.icon-chat-centered-text-filled::before {
  content: "\ebae";
}

.icon-chat-centered-text::before {
  content: "\ebaf";
}

.icon-chat-centered::before {
  content: "\ebb0";
}

.icon-chat-circle-dots-filled::before {
  content: "\ebb1";
}

.icon-chat-circle-dots::before {
  content: "\ebb2";
}

.icon-chat-circle-filled::before {
  content: "\ebb3";
}

.icon-chat-circle-text-filled::before {
  content: "\ebb4";
}

.icon-chat-circle-text::before {
  content: "\ebb5";
}

.icon-chat-circle::before {
  content: "\ebb6";
}

.icon-chat-dots-filled::before {
  content: "\ebb7";
}

.icon-chat-dots::before {
  content: "\ebb8";
}

.icon-chat-filled::before {
  content: "\ebb9";
}

.icon-chat-teardrop-dots-filled::before {
  content: "\ebba";
}

.icon-chat-teardrop-dots::before {
  content: "\ebbb";
}

.icon-chat-teardrop-filled::before {
  content: "\ebbc";
}

.icon-chat-teardrop-text-filled::before {
  content: "\ebbd";
}

.icon-chat-teardrop-text::before {
  content: "\ebbe";
}

.icon-chat-teardrop::before {
  content: "\ebbf";
}

.icon-chat-text-filled::before {
  content: "\ebc0";
}

.icon-chat-text::before {
  content: "\ebc1";
}

.icon-chat::before {
  content: "\ebc2";
}

.icon-chats-circle-filled::before {
  content: "\ebc3";
}

.icon-chats-circle::before {
  content: "\ebc4";
}

.icon-chats-filled::before {
  content: "\ebc5";
}

.icon-chats-teardrop-filled::before {
  content: "\ebc6";
}

.icon-chats-teardrop::before {
  content: "\ebc7";
}

.icon-chats::before {
  content: "\ebc8";
}

.icon-check-circle-filled::before {
  content: "\ebc9";
}

.icon-check-circle::before {
  content: "\ebca";
}

.icon-check-filled::before {
  content: "\ebcb";
}

.icon-check-square-filled::before {
  content: "\ebcc";
}

.icon-check-square-offset-filled::before {
  content: "\ebcd";
}

.icon-check-square-offset::before {
  content: "\ebce";
}

.icon-check-square::before {
  content: "\ebcf";
}

.icon-check::before {
  content: "\ebd0";
}

.icon-checks-filled::before {
  content: "\ebd1";
}

.icon-checks::before {
  content: "\ebd2";
}

.icon-circle-dashed-filled::before {
  content: "\ebd3";
}

.icon-circle-dashed::before {
  content: "\ebd4";
}

.icon-circle-filled::before {
  content: "\ebd5";
}

.icon-circle-half-filled::before {
  content: "\ebd6";
}

.icon-circle-half-tilt-filled::before {
  content: "\ebd7";
}

.icon-circle-half-tilt::before {
  content: "\ebd8";
}

.icon-circle-half::before {
  content: "\ebd9";
}

.icon-circle-wavy-check-filled::before {
  content: "\ebda";
}

.icon-circle-wavy-check::before {
  content: "\ebdb";
}

.icon-circle-wavy-filled::before {
  content: "\ebdc";
}

.icon-circle-wavy-question-filled::before {
  content: "\ebdd";
}

.icon-circle-wavy-question::before {
  content: "\ebde";
}

.icon-circle-wavy-warning-filled::before {
  content: "\ebdf";
}

.icon-circle-wavy-warning::before {
  content: "\ebe0";
}

.icon-circle-wavy::before {
  content: "\ebe1";
}

.icon-circle::before {
  content: "\ebe2";
}

.icon-circles-four-filled::before {
  content: "\ebe3";
}

.icon-circles-four::before {
  content: "\ebe4";
}

.icon-circles-three-filled::before {
  content: "\ebe5";
}

.icon-circles-three-plus-filled::before {
  content: "\ebe6";
}

.icon-circles-three-plus::before {
  content: "\ebe7";
}

.icon-circles-three::before {
  content: "\ebe8";
}

.icon-clipboard-filled::before {
  content: "\ebe9";
}

.icon-clipboard-text-filled::before {
  content: "\ebea";
}

.icon-clipboard-text::before {
  content: "\ebeb";
}

.icon-clipboard::before {
  content: "\ebec";
}

.icon-clock-afternoon-filled::before {
  content: "\ebed";
}

.icon-clock-afternoon::before {
  content: "\ebee";
}

.icon-clock-clockwise-filled::before {
  content: "\ebef";
}

.icon-clock-clockwise::before {
  content: "\ebf0";
}

.icon-clock-counter-clockwise-filled::before {
  content: "\ebf1";
}

.icon-clock-counter-clockwise::before {
  content: "\ebf2";
}

.icon-clock-filled::before {
  content: "\ebf3";
}

.icon-clock::before {
  content: "\ebf4";
}

.icon-closed-captioning-filled::before {
  content: "\ebf5";
}

.icon-closed-captioning::before {
  content: "\ebf6";
}

.icon-cloud-arrow-down-filled::before {
  content: "\ebf7";
}

.icon-cloud-arrow-down::before {
  content: "\ebf8";
}

.icon-cloud-arrow-up-filled::before {
  content: "\ebf9";
}

.icon-cloud-arrow-up::before {
  content: "\ebfa";
}

.icon-cloud-check-filled::before {
  content: "\ebfb";
}

.icon-cloud-check::before {
  content: "\ebfc";
}

.icon-cloud-filled::before {
  content: "\ebfd";
}

.icon-cloud-fog-filled::before {
  content: "\ebfe";
}

.icon-cloud-fog::before {
  content: "\ebff";
}

.icon-cloud-lightning-filled::before {
  content: "\ec00";
}

.icon-cloud-lightning::before {
  content: "\ec01";
}

.icon-cloud-moon-filled::before {
  content: "\ec02";
}

.icon-cloud-moon::before {
  content: "\ec03";
}

.icon-cloud-rain-filled::before {
  content: "\ec04";
}

.icon-cloud-rain::before {
  content: "\ec05";
}

.icon-cloud-slash-filled::before {
  content: "\ec06";
}

.icon-cloud-slash::before {
  content: "\ec07";
}

.icon-cloud-snow-filled::before {
  content: "\ec08";
}

.icon-cloud-snow::before {
  content: "\ec09";
}

.icon-cloud-sun-filled::before {
  content: "\ec0a";
}

.icon-cloud-sun::before {
  content: "\ec0b";
}

.icon-cloud::before {
  content: "\ec0c";
}

.icon-club-filled::before {
  content: "\ec0d";
}

.icon-club::before {
  content: "\ec0e";
}

.icon-code-filled::before {
  content: "\ec0f";
}

.icon-code-simple-filled::before {
  content: "\ec10";
}

.icon-code-simple::before {
  content: "\ec11";
}

.icon-code::before {
  content: "\ec12";
}

.icon-coffee-filled::before {
  content: "\ec13";
}

.icon-coffee::before {
  content: "\ec14";
}

.icon-coin-filled::before {
  content: "\ec15";
}

.icon-coin::before {
  content: "\ec16";
}

.icon-columns-filled::before {
  content: "\ec17";
}

.icon-columns::before {
  content: "\ec18";
}

.icon-command-filled::before {
  content: "\ec19";
}

.icon-command::before {
  content: "\ec1a";
}

.icon-compass-filled::before {
  content: "\ec1b";
}

.icon-compass::before {
  content: "\ec1c";
}

.icon-computer-tower-filled::before {
  content: "\ec1d";
}

.icon-computer-tower::before {
  content: "\ec1e";
}

.icon-copy-filled::before {
  content: "\ec1f";
}

.icon-copy-simple-filled::before {
  content: "\ec20";
}

.icon-copy-simple::before {
  content: "\ec21";
}

.icon-copy::before {
  content: "\ec22";
}

.icon-copyright-filled::before {
  content: "\ec23";
}

.icon-copyright::before {
  content: "\ec24";
}

.icon-corners-in-filled::before {
  content: "\ec25";
}

.icon-corners-in::before {
  content: "\ec26";
}

.icon-corners-out-filled::before {
  content: "\ec27";
}

.icon-corners-out::before {
  content: "\ec28";
}

.icon-cpu-filled::before {
  content: "\ec29";
}

.icon-cpu::before {
  content: "\ec2a";
}

.icon-credit-card-filled::before {
  content: "\ec2b";
}

.icon-credit-card::before {
  content: "\ec2c";
}

.icon-crop-filled::before {
  content: "\ec2d";
}

.icon-crop::before {
  content: "\ec2e";
}

.icon-crosshair-filled::before {
  content: "\ec2f";
}

.icon-crosshair-simple-filled::before {
  content: "\ec30";
}

.icon-crosshair-simple::before {
  content: "\ec31";
}

.icon-crosshair::before {
  content: "\ec32";
}

.icon-crown-filled::before {
  content: "\ec33";
}

.icon-crown-simple-filled::before {
  content: "\ec34";
}

.icon-crown-simple::before {
  content: "\ec35";
}

.icon-crown::before {
  content: "\ec36";
}

.icon-cube-filled::before {
  content: "\ec37";
}

.icon-cube::before {
  content: "\ec38";
}

.icon-currency-btc-filled::before {
  content: "\ec39";
}

.icon-currency-btc::before {
  content: "\ec3a";
}

.icon-currency-circle-dollar-filled::before {
  content: "\ec3b";
}

.icon-currency-circle-dollar::before {
  content: "\ec3c";
}

.icon-currency-cny-filled::before {
  content: "\ec3d";
}

.icon-currency-cny::before {
  content: "\ec3e";
}

.icon-currency-dollar-filled::before {
  content: "\ec3f";
}

.icon-currency-dollar-simple-filled::before {
  content: "\ec40";
}

.icon-currency-dollar-simple::before {
  content: "\ec41";
}

.icon-currency-dollar::before {
  content: "\ec42";
}

.icon-currency-eur-filled::before {
  content: "\ec43";
}

.icon-currency-eur::before {
  content: "\ec44";
}

.icon-currency-gbp-filled::before {
  content: "\ec45";
}

.icon-currency-gbp::before {
  content: "\ec46";
}

.icon-currency-inr-filled::before {
  content: "\ec47";
}

.icon-currency-inr::before {
  content: "\ec48";
}

.icon-currency-jpy-filled::before {
  content: "\ec49";
}

.icon-currency-jpy::before {
  content: "\ec4a";
}

.icon-currency-krw-filled::before {
  content: "\ec4b";
}

.icon-currency-krw::before {
  content: "\ec4c";
}

.icon-currency-rub-filled::before {
  content: "\ec4d";
}

.icon-currency-rub::before {
  content: "\ec4e";
}

.icon-cursor-filled::before {
  content: "\ec4f";
}

.icon-cursor::before {
  content: "\ec50";
}

.icon-database-filled::before {
  content: "\ec51";
}

.icon-database::before {
  content: "\ec52";
}

.icon-desktop-filled::before {
  content: "\ec53";
}

.icon-desktop-tower-filled::before {
  content: "\ec54";
}

.icon-desktop-tower::before {
  content: "\ec55";
}

.icon-desktop::before {
  content: "\ec56";
}

.icon-device-mobile-camera-filled::before {
  content: "\ec57";
}

.icon-device-mobile-camera::before {
  content: "\ec58";
}

.icon-device-mobile-filled::before {
  content: "\ec59";
}

.icon-device-mobile-speaker-filled::before {
  content: "\ec5a";
}

.icon-device-mobile-speaker::before {
  content: "\ec5b";
}

.icon-device-mobile::before {
  content: "\ec5c";
}

.icon-device-tablet-camera-filled::before {
  content: "\ec5d";
}

.icon-device-tablet-camera::before {
  content: "\ec5e";
}

.icon-device-tablet-filled::before {
  content: "\ec5f";
}

.icon-device-tablet-speaker-filled::before {
  content: "\ec60";
}

.icon-device-tablet-speaker::before {
  content: "\ec61";
}

.icon-device-tablet::before {
  content: "\ec62";
}

.icon-diamond-filled::before {
  content: "\ec63";
}

.icon-diamond::before {
  content: "\ec64";
}

.icon-dice-five-filled::before {
  content: "\ec65";
}

.icon-dice-five::before {
  content: "\ec66";
}

.icon-dice-four-filled::before {
  content: "\ec67";
}

.icon-dice-four::before {
  content: "\ec68";
}

.icon-dice-one-filled::before {
  content: "\ec69";
}

.icon-dice-one::before {
  content: "\ec6a";
}

.icon-dice-six-filled::before {
  content: "\ec6b";
}

.icon-dice-six::before {
  content: "\ec6c";
}

.icon-dice-three-filled::before {
  content: "\ec6d";
}

.icon-dice-three::before {
  content: "\ec6e";
}

.icon-dice-two-filled::before {
  content: "\ec6f";
}

.icon-dice-two::before {
  content: "\ec70";
}

.icon-disc-filled::before {
  content: "\ec71";
}

.icon-disc::before {
  content: "\ec72";
}

.icon-discord-logo-filled::before {
  content: "\ec73";
}

.icon-discord-logo::before {
  content: "\ec74";
}

.icon-divide-filled::before {
  content: "\ec75";
}

.icon-divide::before {
  content: "\ec76";
}

.icon-door-filled::before {
  content: "\ec77";
}

.icon-door::before {
  content: "\ec78";
}

.icon-dots-nine-filled::before {
  content: "\ec79";
}

.icon-dots-nine::before {
  content: "\ec7a";
}

.icon-dots-three-circle-filled::before {
  content: "\ec7b";
}

.icon-dots-three-circle-vertical-filled::before {
  content: "\ec7c";
}

.icon-dots-three-circle-vertical::before {
  content: "\ec7d";
}

.icon-dots-three-circle::before {
  content: "\ec7e";
}

.icon-dots-three-filled::before {
  content: "\ec7f";
}

.icon-dots-three-outline-filled::before {
  content: "\ec80";
}

.icon-dots-three-outline-vertical-filled::before {
  content: "\ec81";
}

.icon-dots-three-outline-vertical::before {
  content: "\ec82";
}

.icon-dots-three-outline::before {
  content: "\ec83";
}

.icon-dots-three-vertical-filled::before {
  content: "\ec84";
}

.icon-dots-three-vertical::before {
  content: "\ec85";
}

.icon-dots-three::before {
  content: "\ec86";
}

.icon-download-filled::before {
  content: "\ec87";
}

.icon-download-simple-filled::before {
  content: "\ec88";
}

.icon-download-simple::before {
  content: "\ec89";
}

.icon-download::before {
  content: "\ec8a";
}

.icon-dribbble-logo-filled::before {
  content: "\ec8b";
}

.icon-dribbble-logo::before {
  content: "\ec8c";
}

.icon-drop-filled::before {
  content: "\ec8d";
}

.icon-drop-half-filled::before {
  content: "\ec8e";
}

.icon-drop-half::before {
  content: "\ec8f";
}

.icon-drop::before {
  content: "\ec90";
}

.icon-eject-filled::before {
  content: "\ec91";
}

.icon-eject::before {
  content: "\ec92";
}

.icon-envelope-filled::before {
  content: "\ec93";
}

.icon-envelope-open-filled::before {
  content: "\ec94";
}

.icon-envelope-open::before {
  content: "\ec95";
}

.icon-envelope-simple-filled::before {
  content: "\ec96";
}

.icon-envelope-simple-open-filled::before {
  content: "\ec97";
}

.icon-envelope-simple-open::before {
  content: "\ec98";
}

.icon-envelope-simple::before {
  content: "\ec99";
}

.icon-envelope::before {
  content: "\ec9a";
}

.icon-equals-filled::before {
  content: "\ec9b";
}

.icon-equals::before {
  content: "\ec9c";
}

.icon-eraser-filled::before {
  content: "\ec9d";
}

.icon-eraser::before {
  content: "\ec9e";
}

.icon-eye-closed-filled::before {
  content: "\ec9f";
}

.icon-eye-closed::before {
  content: "\eca0";
}

.icon-eye-filled::before {
  content: "\eca1";
}

.icon-eye-slash-filled::before {
  content: "\eca2";
}

.icon-eye-slash::before {
  content: "\eca3";
}

.icon-eye::before {
  content: "\eca4";
}

.icon-eyedropper-filled::before {
  content: "\eca5";
}

.icon-eyedropper::before {
  content: "\eca6";
}

.icon-face-mask-filled::before {
  content: "\eca7";
}

.icon-face-mask::before {
  content: "\eca8";
}

.icon-facebook-logo-filled::before {
  content: "\eca9";
}

.icon-facebook-logo::before {
  content: "\ecaa";
}

.icon-faders-filled::before {
  content: "\ecab";
}

.icon-faders-horizontal-filled::before {
  content: "\ecac";
}

.icon-faders-horizontal::before {
  content: "\ecad";
}

.icon-faders::before {
  content: "\ecae";
}

.icon-fast-forward-circle-filled::before {
  content: "\ecaf";
}

.icon-fast-forward-circle::before {
  content: "\ecb0";
}

.icon-figma-logo-filled::before {
  content: "\ecb1";
}

.icon-figma-logo::before {
  content: "\ecb2";
}

.icon-file-arrow-down-filled::before {
  content: "\ecb3";
}

.icon-file-arrow-down::before {
  content: "\ecb4";
}

.icon-file-arrow-up-filled::before {
  content: "\ecb5";
}

.icon-file-arrow-up::before {
  content: "\ecb6";
}

.icon-file-filled::before {
  content: "\ecb7";
}

.icon-file-minus-filled::before {
  content: "\ecb8";
}

.icon-file-minus::before {
  content: "\ecb9";
}

.icon-file-plus-filled::before {
  content: "\ecba";
}

.icon-file-plus::before {
  content: "\ecbb";
}

.icon-file-search-filled::before {
  content: "\ecbc";
}

.icon-file-search::before {
  content: "\ecbd";
}

.icon-file-text-filled::before {
  content: "\ecbe";
}

.icon-file-text::before {
  content: "\ecbf";
}

.icon-file-x-filled::before {
  content: "\ecc0";
}

.icon-file-x::before {
  content: "\ecc1";
}

.icon-file::before {
  content: "\ecc2";
}

.icon-fingerprint-filled::before {
  content: "\ecc3";
}

.icon-fingerprint-simple-filled::before {
  content: "\ecc4";
}

.icon-fingerprint-simple::before {
  content: "\ecc5";
}

.icon-fingerprint::before {
  content: "\ecc6";
}

.icon-finn-the-human-filled::before {
  content: "\ecc7";
}

.icon-finn-the-human::before {
  content: "\ecc8";
}

.icon-fire-filled::before {
  content: "\ecc9";
}

.icon-fire-simple-filled::before {
  content: "\ecca";
}

.icon-fire-simple::before {
  content: "\eccb";
}

.icon-fire::before {
  content: "\eccc";
}

.icon-first-aid-filled::before {
  content: "\eccd";
}

.icon-first-aid-kit-filled::before {
  content: "\ecce";
}

.icon-first-aid-kit::before {
  content: "\eccf";
}

.icon-first-aid::before {
  content: "\ecd0";
}

.icon-flag-banner-filled::before {
  content: "\ecd1";
}

.icon-flag-banner::before {
  content: "\ecd2";
}

.icon-flag-filled::before {
  content: "\ecd3";
}

.icon-flag::before {
  content: "\ecd4";
}

.icon-flame-filled::before {
  content: "\ecd5";
}

.icon-flame::before {
  content: "\ecd6";
}

.icon-flashlight-filled::before {
  content: "\ecd7";
}

.icon-flashlight::before {
  content: "\ecd8";
}

.icon-floppy-disk-filled::before {
  content: "\ecd9";
}

.icon-floppy-disk::before {
  content: "\ecda";
}

.icon-folder-filled::before {
  content: "\ecdb";
}

.icon-folder-minus-filled::before {
  content: "\ecdc";
}

.icon-folder-minus::before {
  content: "\ecdd";
}

.icon-folder-notch-filled::before {
  content: "\ecde";
}

.icon-folder-notch-minus-filled::before {
  content: "\ecdf";
}

.icon-folder-notch-minus::before {
  content: "\ece0";
}

.icon-folder-notch-open-filled::before {
  content: "\ece1";
}

.icon-folder-notch-open::before {
  content: "\ece2";
}

.icon-folder-notch-plus-filled::before {
  content: "\ece3";
}

.icon-folder-notch-plus::before {
  content: "\ece4";
}

.icon-folder-notch::before {
  content: "\ece5";
}

.icon-folder-open-filled::before {
  content: "\ece6";
}

.icon-folder-open::before {
  content: "\ece7";
}

.icon-folder-plus-filled::before {
  content: "\ece8";
}

.icon-folder-plus::before {
  content: "\ece9";
}

.icon-folder-simple-filled::before {
  content: "\ecea";
}

.icon-folder-simple-minus-filled::before {
  content: "\eceb";
}

.icon-folder-simple-minus::before {
  content: "\ecec";
}

.icon-folder-simple-plus-filled::before {
  content: "\eced";
}

.icon-folder-simple-plus::before {
  content: "\ecee";
}

.icon-folder-simple::before {
  content: "\ecef";
}

.icon-folder::before {
  content: "\ecf0";
}

.icon-folders-filled::before {
  content: "\ecf1";
}

.icon-folders::before {
  content: "\ecf2";
}

.icon-fork-knife-filled::before {
  content: "\ecf3";
}

.icon-fork-knife::before {
  content: "\ecf4";
}

.icon-frame-corners-filled::before {
  content: "\ecf5";
}

.icon-frame-corners::before {
  content: "\ecf6";
}

.icon-framer-logo-filled::before {
  content: "\ecf7";
}

.icon-framer-logo::before {
  content: "\ecf8";
}

.icon-funnel-filled::before {
  content: "\ecf9";
}

.icon-funnel-simple-filled::before {
  content: "\ecfa";
}

.icon-funnel-simple::before {
  content: "\ecfb";
}

.icon-funnel::before {
  content: "\ecfc";
}

.icon-game-controller-filled::before {
  content: "\ecfd";
}

.icon-game-controller::before {
  content: "\ecfe";
}

.icon-gauge-filled::before {
  content: "\ecff";
}

.icon-gauge::before {
  content: "\ed00";
}

.icon-gear-filled::before {
  content: "\ed01";
}

.icon-gear-six-filled::before {
  content: "\ed02";
}

.icon-gear-six::before {
  content: "\ed03";
}

.icon-gear::before {
  content: "\ed04";
}

.icon-ghost-filled::before {
  content: "\ed05";
}

.icon-ghost::before {
  content: "\ed06";
}

.icon-gif-filled::before {
  content: "\ed07";
}

.icon-gif::before {
  content: "\ed08";
}

.icon-gift-filled::before {
  content: "\ed09";
}

.icon-gift::before {
  content: "\ed0a";
}

.icon-git-branch-filled::before {
  content: "\ed0b";
}

.icon-git-branch::before {
  content: "\ed0c";
}

.icon-git-commit-filled::before {
  content: "\ed0d";
}

.icon-git-commit::before {
  content: "\ed0e";
}

.icon-git-diff-filled::before {
  content: "\ed0f";
}

.icon-git-diff::before {
  content: "\ed10";
}

.icon-git-fork-filled::before {
  content: "\ed11";
}

.icon-git-fork::before {
  content: "\ed12";
}

.icon-git-merge-filled::before {
  content: "\ed13";
}

.icon-git-merge::before {
  content: "\ed14";
}

.icon-git-pull-request-filled::before {
  content: "\ed15";
}

.icon-git-pull-request::before {
  content: "\ed16";
}

.icon-github-logo-filled::before {
  content: "\ed17";
}

.icon-github-logo::before {
  content: "\ed18";
}

.icon-globe-filled::before {
  content: "\ed19";
}

.icon-globe-hemisphere-east-filled::before {
  content: "\ed1a";
}

.icon-globe-hemisphere-east::before {
  content: "\ed1b";
}

.icon-globe-hemisphere-west-filled::before {
  content: "\ed1c";
}

.icon-globe-hemisphere-west::before {
  content: "\ed1d";
}

.icon-globe-simple-filled::before {
  content: "\ed1e";
}

.icon-globe-simple::before {
  content: "\ed1f";
}

.icon-globe-stand-filled::before {
  content: "\ed20";
}

.icon-globe-stand::before {
  content: "\ed21";
}

.icon-globe::before {
  content: "\ed22";
}

.icon-google-logo-filled::before {
  content: "\ed23";
}

.icon-google-logo::before {
  content: "\ed24";
}

.icon-google-play-logo-filled::before {
  content: "\ed25";
}

.icon-google-play-logo::before {
  content: "\ed26";
}

.icon-graduation-cap-filled::before {
  content: "\ed27";
}

.icon-graduation-cap::before {
  content: "\ed28";
}

.icon-grid-four-filled::before {
  content: "\ed29";
}

.icon-grid-four::before {
  content: "\ed2a";
}

.icon-hand-filled::before {
  content: "\ed2b";
}

.icon-hand-fist-filled::before {
  content: "\ed2c";
}

.icon-hand-fist::before {
  content: "\ed2d";
}

.icon-hand-grabbing-filled::before {
  content: "\ed2e";
}

.icon-hand-grabbing::before {
  content: "\ed2f";
}

.icon-hand-palm-filled::before {
  content: "\ed30";
}

.icon-hand-palm::before {
  content: "\ed31";
}

.icon-hand-pointing-filled::before {
  content: "\ed32";
}

.icon-hand-pointing::before {
  content: "\ed33";
}

.icon-hand-soap-filled::before {
  content: "\ed34";
}

.icon-hand-soap::before {
  content: "\ed35";
}

.icon-hand-waving-filled::before {
  content: "\ed36";
}

.icon-hand-waving::before {
  content: "\ed37";
}

.icon-hand::before {
  content: "\ed38";
}

.icon-handbag-filled::before {
  content: "\ed39";
}

.icon-handbag-simple-filled::before {
  content: "\ed3a";
}

.icon-handbag-simple::before {
  content: "\ed3b";
}

.icon-handbag::before {
  content: "\ed3c";
}

.icon-handshake-filled::before {
  content: "\ed3d";
}

.icon-handshake::before {
  content: "\ed3e";
}

.icon-hard-drive-filled::before {
  content: "\ed3f";
}

.icon-hard-drive::before {
  content: "\ed40";
}

.icon-hard-drives-filled::before {
  content: "\ed41";
}

.icon-hard-drives::before {
  content: "\ed42";
}

.icon-hash-filled::before {
  content: "\ed43";
}

.icon-hash-straight-filled::before {
  content: "\ed44";
}

.icon-hash-straight::before {
  content: "\ed45";
}

.icon-hash::before {
  content: "\ed46";
}

.icon-headphones-filled::before {
  content: "\ed47";
}

.icon-headphones::before {
  content: "\ed48";
}

.icon-headset-filled::before {
  content: "\ed49";
}

.icon-headset::before {
  content: "\ed4a";
}

.icon-heart-filled::before {
  content: "\ed4b";
}

.icon-heart-straight-filled::before {
  content: "\ed4c";
}

.icon-heart-straight::before {
  content: "\ed4d";
}

.icon-heart::before {
  content: "\ed4e";
}

.icon-heartbeat-filled::before {
  content: "\ed4f";
}

.icon-heartbeat::before {
  content: "\ed50";
}

.icon-helmet::before {
  content: "\ed51";
}

.icon-hexagon-filled::before {
  content: "\ed52";
}

.icon-hexagon::before {
  content: "\ed53";
}

.icon-highlighter-circle-filled::before {
  content: "\ed54";
}

.icon-highlighter-circle::before {
  content: "\ed55";
}

.icon-horse-filled::before {
  content: "\ed56";
}

.icon-horse::before {
  content: "\ed57";
}

.icon-hourglass-filled::before {
  content: "\ed58";
}

.icon-hourglass-high-filled::before {
  content: "\ed59";
}

.icon-hourglass-high::before {
  content: "\ed5a";
}

.icon-hourglass-low-filled::before {
  content: "\ed5b";
}

.icon-hourglass-low::before {
  content: "\ed5c";
}

.icon-hourglass-medium-filled::before {
  content: "\ed5d";
}

.icon-hourglass-medium::before {
  content: "\ed5e";
}

.icon-hourglass-simple-filled::before {
  content: "\ed5f";
}

.icon-hourglass-simple-high-filled::before {
  content: "\ed60";
}

.icon-hourglass-simple-high::before {
  content: "\ed61";
}

.icon-hourglass-simple-low-filled::before {
  content: "\ed62";
}

.icon-hourglass-simple-low::before {
  content: "\ed63";
}

.icon-hourglass-simple-medium-filled::before {
  content: "\ed64";
}

.icon-hourglass-simple-medium::before {
  content: "\ed65";
}

.icon-hourglass-simple::before {
  content: "\ed66";
}

.icon-hourglass::before {
  content: "\ed67";
}

.icon-house-filled::before {
  content: "\ed68";
}

.icon-house-line-filled::before {
  content: "\ed69";
}

.icon-house-line::before {
  content: "\ed6a";
}

.icon-house-simple-filled::before {
  content: "\ed6b";
}

.icon-house-simple::before {
  content: "\ed6c";
}

.icon-house::before {
  content: "\ed6d";
}

.icon-identification-card-filled::before {
  content: "\ed6e";
}

.icon-identification-card::before {
  content: "\ed6f";
}

.icon-image-filled::before {
  content: "\ed70";
}

.icon-image-square-filled::before {
  content: "\ed71";
}

.icon-image-square::before {
  content: "\ed72";
}

.icon-image::before {
  content: "\ed73";
}

.icon-infinity-filled::before {
  content: "\ed74";
}

.icon-infinity::before {
  content: "\ed75";
}

.icon-info-filled::before {
  content: "\ed76";
}

.icon-info::before {
  content: "\ed77";
}

.icon-instagram-logo-filled::before {
  content: "\ed78";
}

.icon-instagram-logo::before {
  content: "\ed79";
}

.icon-intersect-filled::before {
  content: "\ed7a";
}

.icon-intersect::before {
  content: "\ed7b";
}

.icon-jeep-filled::before {
  content: "\ed7c";
}

.icon-jeep::before {
  content: "\ed7d";
}

.icon-key-filled::before {
  content: "\ed7e";
}

.icon-key::before {
  content: "\ed7f";
}

.icon-keyboard-filled::before {
  content: "\ed80";
}

.icon-keyboard::before {
  content: "\ed81";
}

.icon-knife-filled::before {
  content: "\ed82";
}

.icon-knife::before {
  content: "\ed83";
}

.icon-lamp-filled::before {
  content: "\ed84";
}

.icon-lamp::before {
  content: "\ed85";
}

.icon-laptop-filled::before {
  content: "\ed86";
}

.icon-laptop::before {
  content: "\ed87";
}

.icon-leaf-filled::before {
  content: "\ed88";
}

.icon-leaf::before {
  content: "\ed89";
}

.icon-lifebuoy-filled::before {
  content: "\ed8a";
}

.icon-lifebuoy::before {
  content: "\ed8b";
}

.icon-lightbulb-filament-filled::before {
  content: "\ed8c";
}

.icon-lightbulb-filament::before {
  content: "\ed8d";
}

.icon-lightbulb-filled::before {
  content: "\ed8e";
}

.icon-lightbulb::before {
  content: "\ed8f";
}

.icon-lightning-auto::before {
  content: "\ed90";
}

.icon-lightning-filled::before {
  content: "\ed91";
}

.icon-lightning-slash-filled::before {
  content: "\ed92";
}

.icon-lightning-slash::before {
  content: "\ed93";
}

.icon-lightning::before {
  content: "\ed94";
}

.icon-link-break-filled::before {
  content: "\ed95";
}

.icon-link-break::before {
  content: "\ed96";
}

.icon-link-filled::before {
  content: "\ed97";
}

.icon-link-simple-break-filled::before {
  content: "\ed98";
}

.icon-link-simple-break::before {
  content: "\ed99";
}

.icon-link-simple-filled::before {
  content: "\ed9a";
}

.icon-link-simple-horizontal-break-filled::before {
  content: "\ed9b";
}

.icon-link-simple-horizontal-break::before {
  content: "\ed9c";
}

.icon-link-simple-horizontal-filled::before {
  content: "\ed9d";
}

.icon-link-simple-horizontal::before {
  content: "\ed9e";
}

.icon-link-simple::before {
  content: "\ed9f";
}

.icon-link::before {
  content: "\eda0";
}

.icon-linkedin-logo-filled::before {
  content: "\eda1";
}

.icon-linkedin-logo::before {
  content: "\eda2";
}

.icon-list-bullets-filled::before {
  content: "\eda3";
}

.icon-list-bullets::before {
  content: "\eda4";
}

.icon-list-dashes-filled::before {
  content: "\eda5";
}

.icon-list-dashes::before {
  content: "\eda6";
}

.icon-list-filled::before {
  content: "\eda7";
}

.icon-list-numbers-filled::before {
  content: "\eda8";
}

.icon-list-numbers::before {
  content: "\eda9";
}

.icon-list-plus-filled::before {
  content: "\edaa";
}

.icon-list-plus::before {
  content: "\edab";
}

.icon-list::before {
  content: "\edac";
}

.icon-lock-filled::before {
  content: "\edad";
}

.icon-lock-key-filled::before {
  content: "\edae";
}

.icon-lock-key-open-filled::before {
  content: "\edaf";
}

.icon-lock-key-open::before {
  content: "\edb0";
}

.icon-lock-key::before {
  content: "\edb1";
}

.icon-lock-laminated-filled::before {
  content: "\edb2";
}

.icon-lock-laminated-open-filled::before {
  content: "\edb3";
}

.icon-lock-laminated-open::before {
  content: "\edb4";
}

.icon-lock-laminated::before {
  content: "\edb5";
}

.icon-lock-open-filled::before {
  content: "\edb6";
}

.icon-lock-open::before {
  content: "\edb7";
}

.icon-lock-simple-filled::before {
  content: "\edb8";
}

.icon-lock-simple-open-filled::before {
  content: "\edb9";
}

.icon-lock-simple-open::before {
  content: "\edba";
}

.icon-lock-simple::before {
  content: "\edbb";
}

.icon-lock::before {
  content: "\edbc";
}

.icon-magnet-filled::before {
  content: "\edbd";
}

.icon-magnet-straight-filled::before {
  content: "\edbe";
}

.icon-magnet-straight::before {
  content: "\edbf";
}

.icon-magnet::before {
  content: "\edc0";
}

.icon-magnifying-glass-filled::before {
  content: "\edc1";
}

.icon-magnifying-glass-minus-filled::before {
  content: "\edc2";
}

.icon-magnifying-glass-minus::before {
  content: "\edc3";
}

.icon-magnifying-glass-plus-filled::before {
  content: "\edc4";
}

.icon-magnifying-glass-plus::before {
  content: "\edc5";
}

.icon-magnifying-glass::before {
  content: "\edc6";
}

.icon-map-pin-filled::before {
  content: "\edc7";
}

.icon-map-pin-line-filled::before {
  content: "\edc8";
}

.icon-map-pin-line::before {
  content: "\edc9";
}

.icon-map-pin::before {
  content: "\edca";
}

.icon-map-trifold-filled::before {
  content: "\edcb";
}

.icon-map-trifold::before {
  content: "\edcc";
}

.icon-marker-circle-filled::before {
  content: "\edcd";
}

.icon-marker-circle::before {
  content: "\edce";
}

.icon-martini-filled::before {
  content: "\edcf";
}

.icon-martini::before {
  content: "\edd0";
}

.icon-math-operations-filled::before {
  content: "\edd1";
}

.icon-math-operations::before {
  content: "\edd2";
}

.icon-medal-filled::before {
  content: "\edd3";
}

.icon-medal::before {
  content: "\edd4";
}

.icon-medium-logo-filled::before {
  content: "\edd5";
}

.icon-medium-logo::before {
  content: "\edd6";
}

.icon-megaphone-filled::before {
  content: "\edd7";
}

.icon-megaphone-simple-filled::before {
  content: "\edd8";
}

.icon-megaphone-simple::before {
  content: "\edd9";
}

.icon-megaphone::before {
  content: "\edda";
}

.icon-menu::before {
  content: "\eddb";
}

.icon-microphone-filled::before {
  content: "\eddc";
}

.icon-microphone-slash-filled::before {
  content: "\eddd";
}

.icon-microphone-slash::before {
  content: "\edde";
}

.icon-microphone::before {
  content: "\eddf";
}

.icon-minus-circle-filled::before {
  content: "\ede0";
}

.icon-minus-circle::before {
  content: "\ede1";
}

.icon-minus-filled::before {
  content: "\ede2";
}

.icon-minus::before {
  content: "\ede3";
}

.icon-money-filled::before {
  content: "\ede4";
}

.icon-money::before {
  content: "\ede5";
}

.icon-monitor-filled::before {
  content: "\ede6";
}

.icon-monitor-play-filled::before {
  content: "\ede7";
}

.icon-monitor-play::before {
  content: "\ede8";
}

.icon-monitor::before {
  content: "\ede9";
}

.icon-moon-filled::before {
  content: "\edea";
}

.icon-moon-stars-filled::before {
  content: "\edeb";
}

.icon-moon-stars::before {
  content: "\edec";
}

.icon-moon::before {
  content: "\eded";
}

.icon-mouse-filled::before {
  content: "\edee";
}

.icon-mouse-simple-filled::before {
  content: "\edef";
}

.icon-mouse-simple::before {
  content: "\edf0";
}

.icon-mouse::before {
  content: "\edf1";
}

.icon-music-note-filled::before {
  content: "\edf2";
}

.icon-music-note-simple-filled::before {
  content: "\edf3";
}

.icon-music-note-simple::before {
  content: "\edf4";
}

.icon-music-note::before {
  content: "\edf5";
}

.icon-music-notes-filled::before {
  content: "\edf6";
}

.icon-music-notes-simple-filled::before {
  content: "\edf7";
}

.icon-music-notes-simple::before {
  content: "\edf8";
}

.icon-music-notes::before {
  content: "\edf9";
}

.icon-navigation-arrow-filled::before {
  content: "\edfa";
}

.icon-navigation-arrow::before {
  content: "\edfb";
}

.icon-newspaper-clipping-filled::before {
  content: "\edfc";
}

.icon-newspaper-clipping::before {
  content: "\edfd";
}

.icon-newspaper-filled::before {
  content: "\edfe";
}

.icon-newspaper::before {
  content: "\edff";
}

.icon-note-blank-filled::before {
  content: "\ee00";
}

.icon-note-blank::before {
  content: "\ee01";
}

.icon-note-filled::before {
  content: "\ee02";
}

.icon-note-pencil-filled::before {
  content: "\ee03";
}

.icon-note-pencil::before {
  content: "\ee04";
}

.icon-note::before {
  content: "\ee05";
}

.icon-notebook-filled::before {
  content: "\ee06";
}

.icon-notebook::before {
  content: "\ee07";
}

.icon-notepad-filled::before {
  content: "\ee08";
}

.icon-notepad::before {
  content: "\ee09";
}

.icon-number-circle-eight-filled::before {
  content: "\ee0a";
}

.icon-number-circle-eight::before {
  content: "\ee0b";
}

.icon-number-circle-five-filled::before {
  content: "\ee0c";
}

.icon-number-circle-five::before {
  content: "\ee0d";
}

.icon-number-circle-four-filled::before {
  content: "\ee0e";
}

.icon-number-circle-four::before {
  content: "\ee0f";
}

.icon-number-circle-nine-filled::before {
  content: "\ee10";
}

.icon-number-circle-nine::before {
  content: "\ee11";
}

.icon-number-circle-one-filled::before {
  content: "\ee12";
}

.icon-number-circle-one::before {
  content: "\ee13";
}

.icon-number-circle-seven-filled::before {
  content: "\ee14";
}

.icon-number-circle-seven::before {
  content: "\ee15";
}

.icon-number-circle-six-filled::before {
  content: "\ee16";
}

.icon-number-circle-six::before {
  content: "\ee17";
}

.icon-number-circle-three-filled::before {
  content: "\ee18";
}

.icon-number-circle-three::before {
  content: "\ee19";
}

.icon-number-circle-two-filled::before {
  content: "\ee1a";
}

.icon-number-circle-two::before {
  content: "\ee1b";
}

.icon-number-circle-zero-filled::before {
  content: "\ee1c";
}

.icon-number-circle-zero::before {
  content: "\ee1d";
}

.icon-number-eight-filled::before {
  content: "\ee1e";
}

.icon-number-eight::before {
  content: "\ee1f";
}

.icon-number-five-filled::before {
  content: "\ee20";
}

.icon-number-five::before {
  content: "\ee21";
}

.icon-number-four-filled::before {
  content: "\ee22";
}

.icon-number-four::before {
  content: "\ee23";
}

.icon-number-nine-filled::before {
  content: "\ee24";
}

.icon-number-nine::before {
  content: "\ee25";
}

.icon-number-one-filled::before {
  content: "\ee26";
}

.icon-number-one::before {
  content: "\ee27";
}

.icon-number-seven-filled::before {
  content: "\ee28";
}

.icon-number-seven::before {
  content: "\ee29";
}

.icon-number-six-filled::before {
  content: "\ee2a";
}

.icon-number-six::before {
  content: "\ee2b";
}

.icon-number-square-eight-filled::before {
  content: "\ee2c";
}

.icon-number-square-eight::before {
  content: "\ee2d";
}

.icon-number-square-five-filled::before {
  content: "\ee2e";
}

.icon-number-square-five::before {
  content: "\ee2f";
}

.icon-number-square-four-filled::before {
  content: "\ee30";
}

.icon-number-square-four::before {
  content: "\ee31";
}

.icon-number-square-nine-filled::before {
  content: "\ee32";
}

.icon-number-square-nine::before {
  content: "\ee33";
}

.icon-number-square-one-filled::before {
  content: "\ee34";
}

.icon-number-square-one::before {
  content: "\ee35";
}

.icon-number-square-seven-filled::before {
  content: "\ee36";
}

.icon-number-square-seven::before {
  content: "\ee37";
}

.icon-number-square-six-filled::before {
  content: "\ee38";
}

.icon-number-square-six::before {
  content: "\ee39";
}

.icon-number-square-three-filled::before {
  content: "\ee3a";
}

.icon-number-square-three::before {
  content: "\ee3b";
}

.icon-number-square-two-filled::before {
  content: "\ee3c";
}

.icon-number-square-two::before {
  content: "\ee3d";
}

.icon-number-square-zero-filled::before {
  content: "\ee3e";
}

.icon-number-square-zero::before {
  content: "\ee3f";
}

.icon-number-three-filled::before {
  content: "\ee40";
}

.icon-number-three::before {
  content: "\ee41";
}

.icon-number-two-filled::before {
  content: "\ee42";
}

.icon-number-two::before {
  content: "\ee43";
}

.icon-number-zero-filled::before {
  content: "\ee44";
}

.icon-number-zero::before {
  content: "\ee45";
}

.icon-nut-filled::before {
  content: "\ee46";
}

.icon-nut::before {
  content: "\ee47";
}

.icon-ny-times-logo-filled::before {
  content: "\ee48";
}

.icon-ny-times-logo::before {
  content: "\ee49";
}

.icon-octagon-filled::before {
  content: "\ee4a";
}

.icon-octagon::before {
  content: "\ee4b";
}

.icon-package-filled::before {
  content: "\ee4c";
}

.icon-package::before {
  content: "\ee4d";
}

.icon-paint-brush-broad-filled::before {
  content: "\ee4e";
}

.icon-paint-brush-broad::before {
  content: "\ee4f";
}

.icon-paint-bucket-filled::before {
  content: "\ee50";
}

.icon-paint-bucket::before {
  content: "\ee51";
}

.icon-paper-plane-filled::before {
  content: "\ee52";
}

.icon-paper-plane-right-filled::before {
  content: "\ee53";
}

.icon-paper-plane-right::before {
  content: "\ee54";
}

.icon-paper-plane-tilt-filled::before {
  content: "\ee55";
}

.icon-paper-plane-tilt::before {
  content: "\ee56";
}

.icon-paper-plane::before {
  content: "\ee57";
}

.icon-paperclip-filled::before {
  content: "\ee58";
}

.icon-paperclip-horizontal-filled::before {
  content: "\ee59";
}

.icon-paperclip-horizontal::before {
  content: "\ee5a";
}

.icon-paperclip::before {
  content: "\ee5b";
}

.icon-path-filled::before {
  content: "\ee5c";
}

.icon-path::before {
  content: "\ee5d";
}

.icon-pause-circle-filled::before {
  content: "\ee5e";
}

.icon-pause-circle::before {
  content: "\ee5f";
}

.icon-pause-filled::before {
  content: "\ee60";
}

.icon-pause::before {
  content: "\ee61";
}

.icon-paw-print-filled::before {
  content: "\ee62";
}

.icon-paw-print::before {
  content: "\ee63";
}

.icon-peace-filled::before {
  content: "\ee64";
}

.icon-peace::before {
  content: "\ee65";
}

.icon-pedestrian-filled::before {
  content: "\ee66";
}

.icon-pedestrian::before {
  content: "\ee67";
}

.icon-pen-filled::before {
  content: "\ee68";
}

.icon-pen-nib-filled::before {
  content: "\ee69";
}

.icon-pen-nib-straight-filled::before {
  content: "\ee6a";
}

.icon-pen-nib-straight::before {
  content: "\ee6b";
}

.icon-pen-nib::before {
  content: "\ee6c";
}

.icon-pen::before {
  content: "\ee6d";
}

.icon-pencil-circle-filled::before {
  content: "\ee6e";
}

.icon-pencil-circle::before {
  content: "\ee6f";
}

.icon-pencil-filled::before {
  content: "\ee70";
}

.icon-pencil-line-filled::before {
  content: "\ee71";
}

.icon-pencil-line::before {
  content: "\ee72";
}

.icon-pencil-simple-filled::before {
  content: "\ee73";
}

.icon-pencil-simple::before {
  content: "\ee74";
}

.icon-pencil::before {
  content: "\ee75";
}

.icon-percent-filled::before {
  content: "\ee76";
}

.icon-percent::before {
  content: "\ee77";
}

.icon-phone-call-filled::before {
  content: "\ee78";
}

.icon-phone-call::before {
  content: "\ee79";
}

.icon-phone-disconnect-filled::before {
  content: "\ee7a";
}

.icon-phone-disconnect::before {
  content: "\ee7b";
}

.icon-phone-filled::before {
  content: "\ee7c";
}

.icon-phone-incoming-filled::before {
  content: "\ee7d";
}

.icon-phone-incoming::before {
  content: "\ee7e";
}

.icon-phone-outgoing-filled::before {
  content: "\ee7f";
}

.icon-phone-outgoing::before {
  content: "\ee80";
}

.icon-phone-slash-filled::before {
  content: "\ee81";
}

.icon-phone-slash::before {
  content: "\ee82";
}

.icon-phone-x-filled::before {
  content: "\ee83";
}

.icon-phone-x::before {
  content: "\ee84";
}

.icon-phone::before {
  content: "\ee85";
}

.icon-phosphor-logo-filled::before {
  content: "\ee86";
}

.icon-phosphor-logo::before {
  content: "\ee87";
}

.icon-picture-in-picture-filled::before {
  content: "\ee88";
}

.icon-picture-in-picture::before {
  content: "\ee89";
}

.icon-pinterest-logo-filled::before {
  content: "\ee8a";
}

.icon-pinterest-logo::before {
  content: "\ee8b";
}

.icon-placeholder-filled::before {
  content: "\ee8c";
}

.icon-placeholder::before {
  content: "\ee8d";
}

.icon-planet-filled::before {
  content: "\ee8e";
}

.icon-planet::before {
  content: "\ee8f";
}

.icon-play-circle-filled::before {
  content: "\ee90";
}

.icon-play-circle::before {
  content: "\ee91";
}

.icon-play-filled::before {
  content: "\ee92";
}

.icon-play::before {
  content: "\ee93";
}

.icon-plus-circle-filled::before {
  content: "\ee94";
}

.icon-plus-circle::before {
  content: "\ee95";
}

.icon-plus-filled::before {
  content: "\ee96";
}

.icon-plus-minus-filled::before {
  content: "\ee97";
}

.icon-plus-minus::before {
  content: "\ee98";
}

.icon-plus::before {
  content: "\ee99";
}

.icon-poker-chip-filled::before {
  content: "\ee9a";
}

.icon-poker-chip::before {
  content: "\ee9b";
}

.icon-power-filled::before {
  content: "\ee9c";
}

.icon-power::before {
  content: "\ee9d";
}

.icon-presentation-chart-filled::before {
  content: "\ee9e";
}

.icon-presentation-chart::before {
  content: "\ee9f";
}

.icon-presentation-filled::before {
  content: "\eea0";
}

.icon-presentation::before {
  content: "\eea1";
}

.icon-printer-filled::before {
  content: "\eea2";
}

.icon-printer::before {
  content: "\eea3";
}

.icon-prohibit-filled::before {
  content: "\eea4";
}

.icon-prohibit-inset-filled::before {
  content: "\eea5";
}

.icon-prohibit-inset::before {
  content: "\eea6";
}

.icon-prohibit::before {
  content: "\eea7";
}

.icon-projector-screen-chart-filled::before {
  content: "\eea8";
}

.icon-projector-screen-chart::before {
  content: "\eea9";
}

.icon-projector-screen-filled::before {
  content: "\eeaa";
}

.icon-projector-screen::before {
  content: "\eeab";
}

.icon-push-pin-filled::before {
  content: "\eeac";
}

.icon-push-pin-simple-filled::before {
  content: "\eead";
}

.icon-push-pin-simple-slash-filled::before {
  content: "\eeae";
}

.icon-push-pin-simple-slash::before {
  content: "\eeaf";
}

.icon-push-pin-simple::before {
  content: "\eeb0";
}

.icon-push-pin-slash-filled::before {
  content: "\eeb1";
}

.icon-push-pin-slash::before {
  content: "\eeb2";
}

.icon-push-pin::before {
  content: "\eeb3";
}

.icon-puzzle-piece-filled::before {
  content: "\eeb4";
}

.icon-puzzle-piece::before {
  content: "\eeb5";
}

.icon-qr-code-filled::before {
  content: "\eeb6";
}

.icon-qr-code::before {
  content: "\eeb7";
}

.icon-question-filled::before {
  content: "\eeb8";
}

.icon-question::before {
  content: "\eeb9";
}

.icon-quotes-filled::before {
  content: "\eeba";
}

.icon-quotes::before {
  content: "\eebb";
}

.icon-radical-filled::before {
  content: "\eebc";
}

.icon-radical::before {
  content: "\eebd";
}

.icon-rainbow-cloud-filled::before {
  content: "\eebe";
}

.icon-rainbow-cloud::before {
  content: "\eebf";
}

.icon-rainbow-filled::before {
  content: "\eec0";
}

.icon-rainbow::before {
  content: "\eec1";
}

.icon-receipt-filled::before {
  content: "\eec2";
}

.icon-receipt::before {
  content: "\eec3";
}

.icon-record-filled::before {
  content: "\eec4";
}

.icon-record::before {
  content: "\eec5";
}

.icon-rectangle-filled::before {
  content: "\eec6";
}

.icon-rectangle::before {
  content: "\eec7";
}

.icon-reddit-logo-filled::before {
  content: "\eec8";
}

.icon-reddit-logo::before {
  content: "\eec9";
}

.icon-repeat-filled::before {
  content: "\eeca";
}

.icon-repeat-once-filled::before {
  content: "\eecb";
}

.icon-repeat-once::before {
  content: "\eecc";
}

.icon-repeat::before {
  content: "\eecd";
}

.icon-rewind-circle-filled::before {
  content: "\eece";
}

.icon-rewind-circle::before {
  content: "\eecf";
}

.icon-rocket-filled::before {
  content: "\eed0";
}

.icon-rocket-launch-filled::before {
  content: "\eed1";
}

.icon-rocket-launch::before {
  content: "\eed2";
}

.icon-rocket::before {
  content: "\eed3";
}

.icon-rows-filled::before {
  content: "\eed4";
}

.icon-rows::before {
  content: "\eed5";
}

.icon-rss-filled::before {
  content: "\eed6";
}

.icon-rss-simple-filled::before {
  content: "\eed7";
}

.icon-rss-simple::before {
  content: "\eed8";
}

.icon-rss::before {
  content: "\eed9";
}

.icon-sale::before {
  content: "\eedb";
}

.icon-scissors-filled::before {
  content: "\eedc";
}

.icon-scissors::before {
  content: "\eedd";
}

.icon-screencast-filled::before {
  content: "\eede";
}

.icon-screencast::before {
  content: "\eedf";
}

.icon-scribble-loop-filled::before {
  content: "\eee0";
}

.icon-scribble-loop::before {
  content: "\eee1";
}

.icon-share-filled::before {
  content: "\eee2";
}

.icon-share-network-filled::before {
  content: "\eee3";
}

.icon-share-network::before {
  content: "\eee4";
}

.icon-share::before {
  content: "\eee5";
}

.icon-shield-check-filled::before {
  content: "\eee6";
}

.icon-shield-check::before {
  content: "\eee7";
}

.icon-shield-chevron-filled::before {
  content: "\eee8";
}

.icon-shield-chevron::before {
  content: "\eee9";
}

.icon-shield-filled::before {
  content: "\eeea";
}

.icon-shield-slash-filled::before {
  content: "\eeeb";
}

.icon-shield-slash::before {
  content: "\eeec";
}

.icon-shield-warning-filled::before {
  content: "\eeed";
}

.icon-shield-warning::before {
  content: "\eeee";
}

.icon-shield::before {
  content: "\eeef";
}

.icon-shopping-bag-filled::before {
  content: "\eef0";
}

.icon-shopping-bag-open-filled::before {
  content: "\eef1";
}

.icon-shopping-bag-open::before {
  content: "\eef2";
}

.icon-shopping-bag::before {
  content: "\eef3";
}

.icon-shopping-cart-filled::before {
  content: "\eef4";
}

.icon-shopping-cart-simple-filled::before {
  content: "\eef5";
}

.icon-shopping-cart-simple::before {
  content: "\eef6";
}

.icon-shopping-cart::before {
  content: "\eef7";
}

.icon-shuffle-angular-filled::before {
  content: "\eef8";
}

.icon-shuffle-angular::before {
  content: "\eef9";
}

.icon-shuffle-filled::before {
  content: "\eefa";
}

.icon-shuffle-simple-filled::before {
  content: "\eefb";
}

.icon-shuffle-simple::before {
  content: "\eefc";
}

.icon-shuffle::before {
  content: "\eefd";
}

.icon-sign-in-filled::before {
  content: "\eefe";
}

.icon-sign-in::before {
  content: "\eeff";
}

.icon-sign-out-filled::before {
  content: "\ef00";
}

.icon-sign-out::before {
  content: "\ef01";
}

.icon-sim-card-filled::before {
  content: "\ef02";
}

.icon-sim-card::before {
  content: "\ef03";
}

.icon-sketch-logo-filled::before {
  content: "\ef04";
}

.icon-sketch-logo::before {
  content: "\ef05";
}

.icon-skip-back-circle-filled::before {
  content: "\ef06";
}

.icon-skip-back-circle::before {
  content: "\ef07";
}

.icon-skip-back-filled::before {
  content: "\ef08";
}

.icon-skip-back::before {
  content: "\ef09";
}

.icon-skip-forward-circle-filled::before {
  content: "\ef0a";
}

.icon-skip-forward-circle::before {
  content: "\ef0b";
}

.icon-skip-forward-filled::before {
  content: "\ef0c";
}

.icon-skip-forward::before {
  content: "\ef0d";
}

.icon-slack-logo-filled::before {
  content: "\ef0e";
}

.icon-slack-logo::before {
  content: "\ef0f";
}

.icon-sliders-filled::before {
  content: "\ef10";
}

.icon-sliders-horizontal-filled::before {
  content: "\ef11";
}

.icon-sliders-horizontal::before {
  content: "\ef12";
}

.icon-sliders::before {
  content: "\ef13";
}

.icon-smiley-blank-filled::before {
  content: "\ef14";
}

.icon-smiley-blank::before {
  content: "\ef15";
}

.icon-smiley-filled::before {
  content: "\ef16";
}

.icon-smiley-meh-filled::before {
  content: "\ef17";
}

.icon-smiley-meh::before {
  content: "\ef18";
}

.icon-smiley-nervous-filled::before {
  content: "\ef19";
}

.icon-smiley-nervous::before {
  content: "\ef1a";
}

.icon-smiley-sad-filled::before {
  content: "\ef1b";
}

.icon-smiley-sad::before {
  content: "\ef1c";
}

.icon-smiley-sticker-filled::before {
  content: "\ef1d";
}

.icon-smiley-sticker::before {
  content: "\ef1e";
}

.icon-smiley-wink-filled::before {
  content: "\ef1f";
}

.icon-smiley-wink::before {
  content: "\ef20";
}

.icon-smiley-xeyes-filled::before {
  content: "\ef21";
}

.icon-smiley-xeyes::before {
  content: "\ef22";
}

.icon-smiley::before {
  content: "\ef23";
}

.icon-snapchat-logo-filled::before {
  content: "\ef24";
}

.icon-snapchat-logo::before {
  content: "\ef25";
}

.icon-snowflake-filled::before {
  content: "\ef26";
}

.icon-snowflake::before {
  content: "\ef27";
}

.icon-sort-ascending-filled::before {
  content: "\ef28";
}

.icon-sort-ascending::before {
  content: "\ef29";
}

.icon-sort-descending-filled::before {
  content: "\ef2a";
}

.icon-sort-descending::before {
  content: "\ef2b";
}

.icon-spade-filled::before {
  content: "\ef2c";
}

.icon-spade::before {
  content: "\ef2d";
}

.icon-speaker-high-filled::before {
  content: "\ef2e";
}

.icon-speaker-high::before {
  content: "\ef2f";
}

.icon-speaker-low-filled::before {
  content: "\ef30";
}

.icon-speaker-low::before {
  content: "\ef31";
}

.icon-speaker-none-filled::before {
  content: "\ef32";
}

.icon-speaker-none::before {
  content: "\ef33";
}

.icon-speaker-simple-high-filled::before {
  content: "\ef34";
}

.icon-speaker-simple-high::before {
  content: "\ef35";
}

.icon-speaker-simple-low-filled::before {
  content: "\ef36";
}

.icon-speaker-simple-low::before {
  content: "\ef37";
}

.icon-speaker-simple-none-filled::before {
  content: "\ef38";
}

.icon-speaker-simple-none::before {
  content: "\ef39";
}

.icon-speaker-simple-slash-filled::before {
  content: "\ef3a";
}

.icon-speaker-simple-slash::before {
  content: "\ef3b";
}

.icon-speaker-simple-x-filled::before {
  content: "\ef3c";
}

.icon-speaker-simple-x::before {
  content: "\ef3d";
}

.icon-speaker-slash-filled::before {
  content: "\ef3e";
}

.icon-speaker-slash::before {
  content: "\ef3f";
}

.icon-speaker-x-filled::before {
  content: "\ef40";
}

.icon-speaker-x::before {
  content: "\ef41";
}

.icon-spinner-filled::before {
  content: "\ef42";
}

.icon-spinner-gap-filled::before {
  content: "\ef43";
}

.icon-spinner-gap::before {
  content: "\ef44";
}

.icon-spinner::before {
  content: "\ef45";
}

.icon-spotify-logo-filled::before {
  content: "\ef46";
}

.icon-spotify-logo::before {
  content: "\ef47";
}

.icon-square-filled::before {
  content: "\ef48";
}

.icon-square-half-filled::before {
  content: "\ef49";
}

.icon-square-half::before {
  content: "\ef4a";
}

.icon-square::before {
  content: "\ef4b";
}

.icon-squares-four-filled::before {
  content: "\ef4c";
}

.icon-squares-four::before {
  content: "\ef4d";
}

.icon-stack-filled::before {
  content: "\ef4e";
}

.icon-stack-simple-filled::before {
  content: "\ef4f";
}

.icon-stack-simple::before {
  content: "\ef50";
}

.icon-stack::before {
  content: "\ef51";
}

.icon-star-filled::before {
  content: "\ef52";
}

.icon-star::before {
  content: "\ef53";
}

.icon-sticker-filled::before {
  content: "\ef54";
}

.icon-sticker::before {
  content: "\ef55";
}

.icon-stop-circle-filled::before {
  content: "\ef56";
}

.icon-stop-circle::before {
  content: "\ef57";
}

.icon-stop-filled::before {
  content: "\ef58";
}

.icon-stop::before {
  content: "\ef59";
}

.icon-storefront-filled::before {
  content: "\ef5a";
}

.icon-storefront::before {
  content: "\ef5b";
}

.icon-suitcase-filled::before {
  content: "\ef5c";
}

.icon-suitcase-simple-filled::before {
  content: "\ef5d";
}

.icon-suitcase-simple::before {
  content: "\ef5e";
}

.icon-suitcase::before {
  content: "\ef5f";
}

.icon-sun-dim-filled::before {
  content: "\ef60";
}

.icon-sun-dim::before {
  content: "\ef61";
}

.icon-sun-filled::before {
  content: "\ef62";
}

.icon-sun-horizon-filled::before {
  content: "\ef63";
}

.icon-sun-horizon::before {
  content: "\ef64";
}

.icon-sun::before {
  content: "\ef65";
}

.icon-swatches-filled::before {
  content: "\ef66";
}

.icon-swatches::before {
  content: "\ef67";
}

.icon-sword-filled::before {
  content: "\ef68";
}

.icon-sword::before {
  content: "\ef69";
}

.icon-table-filled::before {
  content: "\ef6a";
}

.icon-table::before {
  content: "\ef6b";
}

.icon-tag-chevron-filled::before {
  content: "\ef6c";
}

.icon-tag-chevron::before {
  content: "\ef6d";
}

.icon-tag-filled::before {
  content: "\ef6e";
}

.icon-tag-simple-filled::before {
  content: "\ef6f";
}

.icon-tag-simple::before {
  content: "\ef70";
}

.icon-tag::before {
  content: "\ef71";
}

.icon-target-filled::before {
  content: "\ef72";
}

.icon-target::before {
  content: "\ef73";
}

.icon-telegram-logo-filled::before {
  content: "\ef74";
}

.icon-telegram-logo::before {
  content: "\ef75";
}

.icon-terminal-filled::before {
  content: "\ef76";
}

.icon-terminal::before {
  content: "\ef77";
}

.icon-text-align-center-filled::before {
  content: "\ef78";
}

.icon-text-align-center::before {
  content: "\ef79";
}

.icon-text-align-justify-filled::before {
  content: "\ef7a";
}

.icon-text-align-justify::before {
  content: "\ef7b";
}

.icon-text-align-left-filled::before {
  content: "\ef7c";
}

.icon-text-align-left::before {
  content: "\ef7d";
}

.icon-text-align-right-filled::before {
  content: "\ef7e";
}

.icon-text-align-right::before {
  content: "\ef7f";
}

.icon-text-bolder-filled::before {
  content: "\ef80";
}

.icon-text-bolder::before {
  content: "\ef81";
}

.icon-text-italic-filled::before {
  content: "\ef82";
}

.icon-text-italic::before {
  content: "\ef83";
}

.icon-text-strikethrough-filled::before {
  content: "\ef84";
}

.icon-text-strikethrough::before {
  content: "\ef85";
}

.icon-text-t-filled::before {
  content: "\ef86";
}

.icon-text-t::before {
  content: "\ef87";
}

.icon-text-underline-filled::before {
  content: "\ef88";
}

.icon-text-underline::before {
  content: "\ef89";
}

.icon-thermometer-cold-filled::before {
  content: "\ef8a";
}

.icon-thermometer-cold::before {
  content: "\ef8b";
}

.icon-thermometer-filled::before {
  content: "\ef8c";
}

.icon-thermometer-hot-filled::before {
  content: "\ef8d";
}

.icon-thermometer-hot::before {
  content: "\ef8e";
}

.icon-thermometer-simple-filled::before {
  content: "\ef8f";
}

.icon-thermometer-simple::before {
  content: "\ef90";
}

.icon-thermometer::before {
  content: "\ef91";
}

.icon-thumbs-down-filled::before {
  content: "\ef92";
}

.icon-thumbs-down::before {
  content: "\ef93";
}

.icon-thumbs-up-filled::before {
  content: "\ef94";
}

.icon-thumbs-up::before {
  content: "\ef95";
}

.icon-ticket-filled::before {
  content: "\ef96";
}

.icon-ticket::before {
  content: "\ef97";
}

.icon-timer-filled::before {
  content: "\ef98";
}

.icon-timer::before {
  content: "\ef99";
}

.icon-toggle-left-filled::before {
  content: "\ef9a";
}

.icon-toggle-left::before {
  content: "\ef9b";
}

.icon-toggle-right-filled::before {
  content: "\ef9c";
}

.icon-toggle-right::before {
  content: "\ef9d";
}

.icon-tote-filled::before {
  content: "\ef9e";
}

.icon-tote-simple-filled::before {
  content: "\ef9f";
}

.icon-tote-simple::before {
  content: "\efa0";
}

.icon-tote::before {
  content: "\efa1";
}

.icon-traffic-sign-filled::before {
  content: "\efa2";
}

.icon-traffic-sign::before {
  content: "\efa3";
}

.icon-train-filled::before {
  content: "\efa4";
}

.icon-train-regional-filled::before {
  content: "\efa5";
}

.icon-train-regional::before {
  content: "\efa6";
}

.icon-train-simple-filled::before {
  content: "\efa7";
}

.icon-train-simple::before {
  content: "\efa8";
}

.icon-train::before {
  content: "\efa9";
}

.icon-translate-filled::before {
  content: "\efaa";
}

.icon-translate::before {
  content: "\efab";
}

.icon-trash-filled::before {
  content: "\efac";
}

.icon-trash-simple-filled::before {
  content: "\efad";
}

.icon-trash-simple::before {
  content: "\efae";
}

.icon-trash::before {
  content: "\efaf";
}

.icon-tray-filled::before {
  content: "\efb0";
}

.icon-tray::before {
  content: "\efb1";
}

.icon-tree-structure-filled::before {
  content: "\efb2";
}

.icon-tree-structure::before {
  content: "\efb3";
}

.icon-trend-down-filled::before {
  content: "\efb4";
}

.icon-trend-down::before {
  content: "\efb5";
}

.icon-trend-up-filled::before {
  content: "\efb6";
}

.icon-trend-up::before {
  content: "\efb7";
}

.icon-triangle-filled::before {
  content: "\efb8";
}

.icon-triangle::before {
  content: "\efb9";
}

.icon-trophy-filled::before {
  content: "\efba";
}

.icon-trophy::before {
  content: "\efbb";
}

.icon-truck-filled::before {
  content: "\efbc";
}

.icon-truck::before {
  content: "\efbd";
}

.icon-tshirt-filled::before {
  content: "\efbe";
}

.icon-tshirt::before {
  content: "\efbf";
}

.icon-twitch-logo-filled::before {
  content: "\efc0";
}

.icon-twitch-logo::before {
  content: "\efc1";
}

.icon-twitter-logo-filled::before {
  content: "\efc2";
}

.icon-twitter-logo::before {
  content: "\efc3";
}

.icon-umbrella-filled::before {
  content: "\efc4";
}

.icon-umbrella-simple-filled::before {
  content: "\efc5";
}

.icon-umbrella-simple::before {
  content: "\efc6";
}

.icon-umbrella::before {
  content: "\efc7";
}

.icon-upload-filled::before {
  content: "\efc8";
}

.icon-upload-simple-filled::before {
  content: "\efc9";
}

.icon-upload-simple::before {
  content: "\efca";
}

.icon-upload::before {
  content: "\efcb";
}

.icon-user-circle-filled::before {
  content: "\efcc";
}

.icon-user-circle-gear-filled::before {
  content: "\efcd";
}

.icon-user-circle-gear::before {
  content: "\efce";
}

.icon-user-circle-minus-filled::before {
  content: "\efcf";
}

.icon-user-circle-minus::before {
  content: "\efd0";
}

.icon-user-circle-plus-filled::before {
  content: "\efd1";
}

.icon-user-circle-plus::before {
  content: "\efd2";
}

.icon-user-circle::before {
  content: "\efd3";
}

.icon-user-filled::before {
  content: "\efd4";
}

.icon-user-gear-filled::before {
  content: "\efd5";
}

.icon-user-gear::before {
  content: "\efd6";
}

.icon-user-minus-filled::before {
  content: "\efd7";
}

.icon-user-minus::before {
  content: "\efd8";
}

.icon-user-plus-filled::before {
  content: "\efd9";
}

.icon-user-plus::before {
  content: "\efda";
}

.icon-user-rectangle-filled::before {
  content: "\efdb";
}

.icon-user-rectangle::before {
  content: "\efdc";
}

.icon-user-square-filled::before {
  content: "\efdd";
}

.icon-user-square::before {
  content: "\efde";
}

.icon-user::before {
  content: "\efdf";
}

.icon-users-filled::before {
  content: "\efe0";
}

.icon-users::before {
  content: "\efe1";
}

.icon-vibrate-filled::before {
  content: "\efe2";
}

.icon-vibrate::before {
  content: "\efe3";
}

.icon-video-camera-filled::before {
  content: "\efe4";
}

.icon-video-camera-slash-filled::before {
  content: "\efe5";
}

.icon-video-camera-slash::before {
  content: "\efe6";
}

.icon-video-camera::before {
  content: "\efe7";
}

.icon-voicemail-filled::before {
  content: "\efe8";
}

.icon-voicemail::before {
  content: "\efe9";
}

.icon-wall-filled::before {
  content: "\efea";
}

.icon-wall::before {
  content: "\efeb";
}

.icon-wallet-filled::before {
  content: "\efec";
}

.icon-wallet::before {
  content: "\efed";
}

.icon-warning-circle-filled::before {
  content: "\efee";
}

.icon-warning-circle::before {
  content: "\efef";
}

.icon-warning-filled::before {
  content: "\eff0";
}

.icon-warning-octagon-filled::before {
  content: "\eff1";
}

.icon-warning-octagon::before {
  content: "\eff2";
}

.icon-warning::before {
  content: "\eff3";
}

.icon-watch-filled::before {
  content: "\eff4";
}

.icon-watch::before {
  content: "\eff5";
}

.icon-whatsapp-logo-filled::before {
  content: "\eff6";
}

.icon-whatsapp-logo::before {
  content: "\eff7";
}

.icon-wheelchair-filled::before {
  content: "\eff8";
}

.icon-wheelchair::before {
  content: "\eff9";
}

.icon-wifi-high-filled::before {
  content: "\effa";
}

.icon-wifi-high::before {
  content: "\effb";
}

.icon-wifi-low-filled::before {
  content: "\effc";
}

.icon-wifi-low::before {
  content: "\effd";
}

.icon-wifi-medium-filled::before {
  content: "\effe";
}

.icon-wifi-medium::before {
  content: "\efff";
}

.icon-wifi-none-filled::before {
  content: "\f000";
}

.icon-wifi-none::before {
  content: "\f001";
}

.icon-wifi-slash-filled::before {
  content: "\f002";
}

.icon-wifi-slash::before {
  content: "\f003";
}

.icon-wifi-x-filled::before {
  content: "\f004";
}

.icon-wifi-x::before {
  content: "\f005";
}

.icon-wind-filled::before {
  content: "\f006";
}

.icon-wind::before {
  content: "\f007";
}

.icon-wrench-filled::before {
  content: "\f008";
}

.icon-wrench::before {
  content: "\f009";
}

.icon-x-filled::before {
  content: "\f00a";
}

.icon-x::before {
  content: "\f00b";
}

.icon-xcircle-filled::before {
  content: "\f00c";
}

.icon-xcircle::before {
  content: "\f00d";
}

.icon-xsquare-filled::before {
  content: "\f00e";
}

.icon-xsquare::before {
  content: "\f00f";
}

.icon-youtube-logo-filled::before {
  content: "\f010";
}

.icon-youtube-logo::before {
  content: "\f011";
}

.icon-sale-filled::before {
  content: "\f012";
}

